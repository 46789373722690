<template>
  <div>
    <navbar></navbar>
    <banniere></banniere>
    <services></services>
    <Speciality/>
    <food></food>
    <reservation></reservation>
    <feedback></feedback>
    <chefs></chefs>
    <MyAbout></MyAbout>
    <Contact/>
  
  </div>
</template>

<script>
import Banniere from "../components/MyBanniere.vue";
import Chefs from '../components/MyChef.vue';
import Feedback from "../components/MyFeedBack.vue";
import Reservation from "../components/MyReservation.vue";
import services from "../components/MyServices.vue";
import Contact from "../components/MyContact.vue";
import Food from '../components/MyFood.vue';
import Navbar from '../components/MyNavbar.vue';
import Speciality from '../components/MySpeciality.vue'
import MyAbout from '../components/MyAbout.vue';
export default {
  components: {
    MyAbout,
    Chefs,
    Contact,
    Feedback,
    Reservation,
    Food,
    Speciality,
    services,
    Banniere, 
    Navbar
  }
};
</script>
    

<style scoped>
</style>
