import { createStore } from "vuex";

const store = createStore({
  state: {
    count: 1,
    panier: [],
    user: {}
  },
  getters: {
    getCart(state) {
      return state.panier;
    },
    priceTotal(state) {
      let total = 0;
      state.panier.map((x) => {
        total += Number(x.price);
      });
      return total;
    },
    // items(state) {
    //   let total = 0;
    //   state.panier.map(() => {
    //     total += 1;
    //   });
    //   return total;
    // },
    getCurrentUser(state){
      return state.user
    },

  },
  mutations: {
    increment(state) {
      state.count++;
    },
    ajoutPanier(state, playload) {
      state.panier = playload;
    },
    removePanier(state, playload) {
      state.panier.splice(playload, 1);
    },
    setCurrentUser(state, playload){
      state.user = playload
    },
  },
  actions: {
    async ajoutPanier({ commit }, playload){
      commit("ajoutPanier", playload)
    },

    async setCurrentUser({ commit }, playload){
      commit("setCurrentUser", playload)
    }

  },
  modules: {},
});

export default store;
