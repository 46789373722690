<template>
  <div>
    <Navbar></Navbar>
    <header>
      <div>
        <h2>{{ detail.name }}</h2>
        <li>
          <router-link to="/">Accueil /</router-link
          ><router-link to="/menu"> Menu </router-link> / {{ detail.name }}
        </li>
      </div>
    </header>
    <section class="detail">
      <div class="first">
        <div class="img">
          <!-- <img :src="`http://127.0.0.1:8000${detail.image}`" alt="plat" /> -->
          <img
          src="@/assets/burger/burger1.png"
          alt="plat"
        />
        </div>
        <div class="details">
          <h2>{{ detail.name }}</h2>
          <p class="price">{{ detail.price }} Fcfa</p>
          <p class="descriptif">
            {{detail.description}}
          </p>

          <router-link to="/order">
            <button class="btn" @click="addPanier(detail)">
              Ajouter au panier
            </button></router-link
          >
        </div>
      </div>

      <div class="second">
        <div class="title">
          <h2 @click="choose(1)">Description</h2>
          <h3 @click="choose(2)">Commentaires</h3>
        </div>
        <div
          class="description"
          v-if="des"
        >
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque ipsa
            dolores eveniet suscipit autem molestiae aliquid ipsam sapiente
            iusto voluptatibus!
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque ipsa
            dolores eveniet suscipit autem molestiae aliquid ipsam sapiente
            iusto voluptatibus!
          </p>
        </div>
        <div
          class="reviews"
          v-if="reviews"
        >
          <div class="form">
            <h3>laissez un commentaire</h3>
            <form>
              <div class="input">
                <input
                  type="text"
                  name="name"
                  required
                  placeholder="Ton nom"
                  id="name"
                />
                <input
                  type="email"
                  required
                  placeholder="Ton mail"
                  name="email"
                  id="email"
                />
                <input
                  type="text"
                  required
                  placeholder="Sujet"
                  name="Subject"
                  id="Subject"
                />
              </div>
              <textarea
                name="message"
                placeholder="Message"
                id="message"
                cols="30"
                rows="10"
              ></textarea>
              <input type="submit" class="btn" value="Postez un commentaire" />
            </form>
          </div>
        </div>
      </div>
    </section>
    <contact></contact>
  </div>
</template>

<script>
// aos
import AOS from "aos";
import "aos/dist/aos.css";

// components
import Contact from "../../components/MyContact.vue";
import Navbar from "../../components/MyNavbar.vue";
import axios from "axios";
import store from "../../store";
import localforage from "localforage";
export default {
  created() {
    AOS.init();
  },
  mounted() {
    let id = this.$route.params.id;
    axios
      .get(`food/one/${id}`)
      .then((response) => {
        this.detail = response.data;
      })
      .catch((error) => {
        console.log(error.message);
      });
  },
  components: {
    Contact,
    Navbar,
  },
  data() {
    return {
      des: true,
      reviews: false,
      detail: {},
    };
  },
  methods: {
    choose(a) {
      this.des = a == 1 ? true : false;
      this.reviews = a == 2 ? true : false;
    },
    addPanier(detail) {
      localforage.getItem("food").then((array) => {
        if (array) {
          array.length > 0
            ? array.push({ ...detail })
            : (array = [{ ...detail }]);

          localforage
            .setItem("food", array)
            .then((foodAdded) => {
              store.commit("ajoutPanier", foodAdded);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          array = [{ ...detail }];

          localforage
            .setItem("food", array)
            .then((foodAdded) => {
              store.commit("ajoutPanier", foodAdded);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
header {
  background-image: url("~@/assets/blog-8.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

header div {
  color: var(--color1);
  font-size: 20px;
  font-family: var(--first-police);
}

header div h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--first-police);
  text-align: center;
}

h2 {
  font-size: 20px;
  text-transform: capitalize;
  font-family: var(--first-police);
}

/* first */
.first {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.details {
  width: 50%;
  line-height: 1.5;
}

.title {
  display: flex;
  padding: 10px 30px;
  border: 1px solid var(--border-color);
}

.title h2,
.title h3 {
  font-size: 18px;
  color: var(--color2);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--first-police);
  cursor: pointer;
}

.title h3 {
  margin-left: 10px;
}

.price {
  color: var(--color2);
  font-size: 18px;
  font-weight: 700;
}

.descriptif {
  font-size: 18px;
  text-transform: capitalize;
  font-family: var(--first-police);
}

.btn {
  display: inline;
  margin-top: 10px;
}

/* second */
.second {
  border: 1px solid var(--border-color);
  width: 80%;
  margin: 10px auto;
}

/* description */
.description {
  padding: 10px 30px;
}

.description p {
  color: #999999;
  padding-bottom: 20px;
}

/* reviews */
.reviews {
  padding: 0px 30px;
}

.form h3 {
  margin: 10px 0px;
  font-size: 18px;
  color: var(--color3);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--first-police);
  cursor: pointer;
}

.input {
  display: flex;
  justify-content: space-between;
}

.input input {
  width: 30%;
  padding: 10px;
  outline: none;
  border: 1px solid var(--border-color);
}

form textarea {
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  outline: none;
  border: 1px solid var(--border-color);
}

input[type="submit"] {
  margin: 20px 0px;
  border-radius: 0;
  cursor: pointer;
}

input::placeholder,
textarea::placeholder {
  font-family: var(--first-police);
  font-size: 15px;
  color: var(--color3);
}

/* responsive */
@media screen and (max-width: 800px) {
  .first {
    flex-direction: column;
  }

  .details{
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .input {
    flex-direction: column;
  }

  .input input {
    margin-top: 10px;
    width: 100%;
  }

  .details {
    width: 80%;
  }
}

@media screen and (max-width: 450px) {
  .second {
    width: 90%;
  }
}
</style>