<template>
  <div>
    <navbar></navbar>
    <header>
      <div>
        <h2>Reservation</h2>
        <li><router-link to="/">Accueil </router-link>/ Reservation</li>
      </div>
    </header>
    <section class="booking" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="400">
      <h3>Faites vos reservations en ligne</h3>
      <h4>Réservez une table</h4>
      <form @submit.prevent="addReservation" class="contact-information">
        <input type="text" placeholder="Votre nom" name="" id="" v-model="reservation.name"/>
        <input type="mail" placeholder="Votre mail" name="" id="" v-model="reservation.email"/>
        <input type="number" placeholder="Nombre de places" v-model="reservation.place"/>
        <input
          type="tel"
          placeholder="Votre numero de telephone"
          name=""
          id=""
          v-model="reservation.number"
        />
        <input
          type="date"
          placeholder="La date de reservation "
          name=""
          id=""
          v-model="reservation.reservationDate"
        />
        <button class="btn">Réservez maintenant</button>
      </form>
    </section>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "../../components/MyContact.vue";
import Navbar from "../../components/MyNavbar.vue";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

// axios
import axios from 'axios'

export default {
  created(){
    AOS.init();
  },
  data(){
    return {
      reservation:{
        name:null,
        email:null,
        place:null,
        number:null,
        reservationDate:null
      }
    }
  },
  components: {
    Contact,
    Navbar,
  },
  methods:{
    addReservation(){

      axios.post('reservation/booking', {... this.reservation}).then((response)=>{
        console.log(this.reservation);
        const {data} = response
        if (data.status == "success") {
          alert(data.message)
        } else {
          alert(data.message)
        }
      }).catch((error)=>{
        console.log(error);
      })
    }
  }
};
</script>

<style scoped>
header {
  background-image: url("~@/assets/blog-6.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  
  align-items: center;
}

header div {
  color: var(--color1);
  font-size: 20px;
  font-family: var(--first-police);
}

header div h2{
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--first-police);
}

.booking {
  height: auto;
}

.booking h3 {
  font-family: var(--cursive-police);
  color: var(--color2);
  text-align: center;
}

.booking h4 {
  font-family: var(--first-police);
  text-transform: uppercase;
  text-align: center;
  font-size: 50px;
}

.contact-information {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
}

.contact-information input {
  padding: 10px;
  margin-top: 30px;
  outline: none;
  border-radius: 5px;
  border: none;
  box-shadow: inset 6px 6px 6px #cbced1;
  background-color: #ecf0f3;
}

input::placeholder {
  color: gray;
}

.contact-information input:focus {
  outline: none;
}

.btn {
  padding: 10px;
  border-radius: 5px;
  border: 0px;
  background-color: var(--color2);
  color: var(--color1);
  text-transform: uppercase;
  font-family: var(--first-police);
  outline: none;
  width: 200px;
  margin: 0px auto;
  display: block;
  margin-top: 30px;
}

button:hover {
  color: var(--color2);
  background-color: var(--color1);
  border: 1px solid var(--color2);
}

@media screen and (max-width: 600px){
  .contact-information{
    width: 80%;
  }
}
</style>