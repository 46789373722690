<template>
  <div>
    <!-- commandes -->
    <section class="command" v-if="allCommand">
      <p v-if="reponse">{{ detail }}</p>
      <h2>Commandes</h2>
      <div v-if="commandTable">
        <table>
          <tr>
            <th>Id</th>
            <th>food name</th>
            <th>user name</th>
            <th>Nombre d'elements</th>
            <th>date</th>
            <th>validation</th>
            <th>Prix</th>
            <th>Actions</th>
          </tr>
          <tr v-for="(commande, i) in commandes" :key="i">
            <td>{{ commande.id }}</td>
            <td>{{ commande.food.name }}</td>
            <td>{{ commande.user.name }}</td>
            <td>{{ commande.number }}</td>
            <td>{{ commande.commandeDate }}</td>
            <td>{{ commande.validation }}</td>
            <td>{{ commande.price }}</td>

            <td class="icons">
              <p class="supprimer" @click="seeCommand(commande.id)">
                <i class="fas fa-check"></i> Voir la commande
              </p>
              <p class="supprimer" @click="deleteOrder(commande.id)">
                <i class="fas fa-trash"></i> supprimer
              </p>
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section class="order" v-if="order">
      <header>
        <h2>Facture</h2>
        <div class="order-sub" v-for="(contact, i) in contacts" :key="i">
          <div class="project">
            <div><span>Nom</span> {{ contact.contact.name }}</div>
            <div><span>Prenom</span> {{ contact.contact.name }}</div>
            <div><span>ADDRESS</span> {{ contact.contact.adresse }}</div>
            <div>
              <span>Telephone</span>
              {{ contact.contact.tel }}
            </div>
            <div><span>Email</span> {{ contact.email }}</div>
          </div>
          <div class="company">
            <div>Company Name</div>
            <div>
              455 Foggy Heights,<br />
              AZ 85004, US
            </div>
            <div>(602) 519-0450</div>
            <div>
              <a href="mailto:company@example.com">company@example.com</a>
            </div>
          </div>
        </div>
      </header>
      <main>
        <table>
          <thead>
            <tr>
              <th class="service">Nom</th>
              <th class="desc">Date</th>
              <th>PRICE</th>
              <th>QTY</th>
            </tr>
          </thead>
          <tbody v-for="(command, i) in detailCommand" :key="i">
            <tr>
              <td class="service">{{ command.food.name }}</td>
              <td class="desc">
                {{ command.commandeDate }}
              </td>
              <td class="unit">{{ command.price }} Fcfa</td>
              <td class="qty">{{ command.number }}</td>
            </tr>

            <tr>
              <td colspan="3" class="grand total">TOTAL</td>
              <td class="grand total">{{ command.price }} Fcfa</td>
            </tr>
            <button class="btn" @click="validate(command.id)">Valider</button>
          </tbody>
        </table>
      </main>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  mounted() {
    axios
      .get("commande/getOrder")
      .then((response) => {
        this.commandes = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  data() {
    return {
      commandTable: true,
      commandDetail: false,
      detailCommand: [],
      commandes: [],
      contacts: [],
      reponse: false,
      detail: "",
      order: false,
      allCommand: true,
    };
  },
  methods: {
    deleteOrder(id) {
      let confirm = window.confirm(
        "Voulez-vous vraiment supprimé cette commande ?"
      );

      if (confirm) {
        axios
          .delete(`commande/delete/${id}`)
          .then((response) => {
            this.reponse = true;
            this.detail = response.data.message;
          })
          .catch((error) => {
            this.reponse = true;
            this.detail = error.message;
          });
      }
    },
    seeCommand(id) {
      this.commandTable = this.commandTable === true ? false : true;
      this.commandDetail = this.commandDetail === false ? true : false;
      let idCommande = this.commandes.filter((commande) => commande.id == id);
      let commnandId = idCommande[0].id;
      this.order = true;
      this.allCommand = false;
      axios
        .get(`commande/one/${commnandId}`)
        .then((response) => {
          this.detailCommand = response.data.data;
          console.log(response.data);
          this.contacts = response.data.contact;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    validate(id) {
      console.log(id);
      axios
        .post(`commande/validation/${id}`, { validation: true })
        .then((response) => {
          alert(response.data.message);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
section {
  padding: 20px 0px;
}

.command {
  position: relative;
  margin-top: 20px;
  overflow-x: auto;
}

.command h2 {
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.command > p {
  text-align: center;
  font-size: 20px;
  text-transform: capitalize;
  color: var(--title-color);
  font-family: var(--second-police);
}

.command table {
  border-collapse: collapse;
  border: 1px solid var(--border-color);
  width: 90%;
  margin: auto;
}

.command th,
.command td {
  border: 1px solid var(--border-color);
  padding: 15px;
  font-family: var(--second-police);
  font-size: 18px;
  text-align: center;
}

.command,
.supprimer {
  cursor: pointer;
}

.icons {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid var(--border-color);
  padding: 15px;
}

.icons p {
  cursor: pointer;
  padding-left: 15px;
  margin-top: 20px;
  text-align: center;
}

.icons p:hover {
  text-decoration: underline;
}

.detail {
  border: 1px solid var(--border-color);
  width: 750px;
  margin: 10px auto;
}

/* facture */
.order {
  width: 60%;
  margin: auto;
}

header {
  padding: 10px 0;
  margin-bottom: 30px;
}

header .order-sub {
  display: flex;
  justify-content: space-between;
}

.order h2 {
  border-top: 1px solid #5d6975;
  border-bottom: 1px solid #5d6975;
  color: #5d6975;
  font-size: 2.4em;
  line-height: 1.4em;
  font-weight: normal;
  text-align: center;
  margin: 0 0 20px 0;
  /* background: url("../../assets/dimension.png"); */
  text-align: center;
}

.project span {
  color: #5d6975;
  text-align: right;
  width: 52px;
  margin-right: 10px;
  display: inline-block;
  font-size: 0.8em;
}

.project div,
.company div {
  white-space: nowrap;
}

.order table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

.order table tr:nth-child(2n-1) td {
  background: #f5f5f5;
}

.order table th,
.order table td {
  text-align: center;
}

.order table th {
  padding: 5px 20px;
  color: #5d6975;
  border-bottom: 1px solid #c1ced9;
  white-space: nowrap;
  font-weight: normal;
}

.order table .service,
.order table .desc {
  text-align: left;
}

.order table td {
  padding: 20px;
  text-align: right;
}

.order table td.service,
.order table td.desc {
  vertical-align: top;
}

.order table td.unit,
.order table td.qty,
.order table td.total {
  font-size: 1.2em;
}

.order table td.grand {
  border-top: 1px solid #5d6975;
}
</style>