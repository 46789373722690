<template>
  <div>
    <Navbar></Navbar>
    <header>
      <div>
        <h2>Contact</h2>
        <li><router-link to="/">Accueil</router-link>/ Contact</li>
      </div>
    </header>
    <section class="contact">
      <h3>Nous contacter</h3>
      <div class="contact-sub">
        <div class="details">
          <h4>Details de contact</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta
            debitis in at sapiente quidem est. Exercitationem iste reprehenderit
            odio officia voluptatem, porro non! Dolorem inventore sed non
            accusantium nemo qui.
          </p>

          <div class="icones">
            <p><i class="fas fa-home fa-2x"></i> 22B BAKER STREET</p>
            <p><i class="fas fa-phone-alt fa-2x"></i> +242 06 453 45 23</p>
            <p><i class="fas fa-envelope fa-2x"></i> merite@gmail.com</p>
            <p><i class="far fa-clock fa-2x"></i> Lundi-Dimanche: 08H-18H</p>
          </div>
        </div>

        <form>
          <input type="email" placeholder="Email" required/>
          <input type="text" placeholder="Sujet" required/>
          <input type="tel" placeholder="Telephone" required/>
          <textarea
            name=""
            placeholder="message"
            id=""
            cols="30"
            rows="10"
            required
          ></textarea>
          <button class="btn">Envoyez votre message</button>
        </form>
      </div>
    </section>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "../../components/MyContact.vue";
import Navbar from "../../components/MyNavbar.vue";

export default {
  components: {
    Contact,
    Navbar,
  },
};
</script>

<style scoped>
header {
  background-image: url("~@/assets/blog-8.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

header div {
  color: var(--color1);
  font-size: 20px;
  font-family: var(--first-police);
}

header div h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--first-police);
  text-align: center;
}

.contact {
  height: auto;
}

.contact h3 {
  font-family: var(--cursive-police);
  text-align: center;
  color: var(--color2);
  font-size: 25px;
}

.contact-sub {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.details {
  width: 40%;
  margin: auto;
}

.details h4 {
  font-size: 25px;
  font-family: var(--first-police);
  text-align: center;
}

.details p{
  font-size: 15px;
  font-family: var(--second-police);
  margin-top: 10px;
}

.icones {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.icones p {
  border: 1px solid var(--border-color);
  padding: 10px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 5px 5px 9px #cecece, -5px -5px 9px #f2f2f2;
  font-size: 20px;
  font-family: var(--first-police);
  margin-top: 10px;
  text-align: center;
}

.icones p:hover {
  animation: pulse 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

.icones i {
  color: var(--color2);
  margin-bottom: 10px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  margin: auto;
}

form input,
form textarea {
  margin-top: 20px;
}

form input,
form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  outline: none;
  border-radius: 5px;
  border: none;
  box-shadow: inset 6px 6px 6px #cbced1;
  background-color: #ecf0f3;
}

form textarea {
  width: 100%;
}

input::placeholder,
textarea::placeholder {
  text-transform: capitalize;
}

.btn {
  border-radius: 5px;
  border: 0px;
  background-color: var(--color2);
  color: var(--color1);
  text-transform: uppercase;
  font-family: var(--first-police);
  outline: none;
  margin-top: 10px;
  width: 100%;
}

button:hover {
  color: var(--color2);
  background-color: var(--color1);
  border: 1px solid var(--color2);
}

@media screen and (max-width: 700px) {
  .contact-sub {
    flex-direction: column;
  }

  .details {
    width: 60%;
    margin: 10px auto;
  }

  .details h4 {
    text-align: center;
  }

  .details p {
    text-align: center;
  }

  form {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  form {
    width: 70%;
  }

  .icones{
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .icones p{
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
}
</style>