<template>
  <div>
    <header>
      <div class="number">
        <p class="special">Commandez votre nourriture en ligne <br /></p>
        <p><span> Appelez nous au</span> 06-34-54</p>
      </div>
    </header>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
header {
  height: 50vh;
  background-image: url("~@/assets/Banniere/banniere2.jpg");
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  color: var(--color1);
  position: relative;
}

.number {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  text-align: center;
}

.number p:first-of-type {
  font-size: 30px;
  text-align: center;
}

.number p:last-of-type {
  margin-top: 15px;
  font-size: 20px;
}

.number:nth-child(2) span {
  color: yellow;
}

@media screen and (max-width: 1150px) {
  header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>