<template>
  <div>
    <section class="online-book">
      <div
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="400"
        class="online-des"
      >
        <p>Tout droit de Merite Food</p>
        <h2>Réservez en ligne</h2>

        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium
          deleniti illum cupiditate vitae voluptatem deserunt, quos,
          perspiciatis facere impedit amet, repellendus atque ea delectus odio
          minima voluptatum consequuntur repudiandae quidem.
        </p>

        <p class="online-call">+242-06-000-00-00</p>
      </div>

      <form
        class="contact-information"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="500"
        @submit.prevent="addReservation"
      >
        <input
          type="text"
          placeholder="Votre nom"
          name="name"
          id="name"
          v-model="reservation.name"
        />
        <input
          type="mail"
          placeholder="Votre mail"
          name="mail"
          id="mail"
          v-model="reservation.email"
        />
        <input
          type="number"
          placeholder="nbre de places"
          v-model="reservation.place"
        />
        <input
          type="tel"
          placeholder="Votre numero de telephone"
          name="tel"
          id="tel"
          v-model="reservation.number"
        />
        <input
          type="date"
          placeholder="La date de reservation "
          name="date"
          id="date"
          v-model="reservation.reservationDate"
        />
        <button class="book-now">Réservez maintenant</button>
      </form>
    </section>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

// axios
import axios from "axios";
export default {
  created() {
    AOS.init();
  },
  data() {
    return {
      reservation: {
        name: null,
        email: null,
        place: null,
        number: null,
        reservationDate: null,
      },
    };
  },
  methods: {
    addReservation() {
      axios
        .post("reservation/booking", { ...this.reservation })
        .then((response) => {
          const { data } = response;
          if (data.status == "success") {
            alert(data.message);
          } else {
            alert(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.online-book {
  height: auto;
  display: flex;
  justify-content: space-around;
  padding: 50px 0;
}

.online-book h2 {
  font-family: "Pacifico", cursive;
  color: #eaa023;
}

.online-des p {
  font-family: var(--second-police);
}

.online-call {
  color: white;
  border: solid #eaa023 6px;
  padding: 10px;
  background-color: black;
  font-size: 20px;
  display: block;
  width: 40%;
  margin-top: 10px;
  transition: all 0.3s;
}

.online-call:hover {
  background-color: white;
  color: black;
}

.online-des {
  width: 40%;
  margin-top: 50px;
}

.online-des h2 {
  font-size: 35px;
}

.online-des p:first-child {
  font-family: var(--first-police);
  font-size: 20px;
}

.contact-information {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.contact-information input {
  padding: 10px;
  margin-top: 20px;
  outline: none;
  border-radius: 5px;
  border: none;
  box-shadow: inset 6px 6px 6px #cbced1;
  background-color: #ecf0f3;
}

input::placeholder {
  color: gray;
}

.contact-information input:focus {
  outline: none;
}

.book-now {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin: auto;
  outline: none;
  border: none;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: all 0.4s;
  margin-top: 20px;
}

.book-now:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}

/* responsive */
@media screen and (max-width: 850px) {
  .online-book {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .online-des {
    text-align: center;
  }

  .online-call {
    width: 65%;
    text-align: center;
    margin: auto;
  }
}

@media screen and (max-width: 750px) {
  .author {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .online-call {
    width: 80%;
  }

  .online-des {
    width: 70%;
  }

  .online-des h2 {
    text-align: center;
  }

  .online-book p {
    text-align: center;
  }

  .contact-information {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .online-call {
    width: 90%;
    margin: auto;
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .online-call {
    width: 100%;
    /* border: none;
    background: none;
    color: var(--color2);
    text-decoration: underline;
    margin-right: 20px; */
    margin-top: 10px;
  }

  .online-des {
    /* width: 45%; */
    margin: auto;
  }

  .contact-information {
    /* width: 45%; */
    margin: auto;
  }

  .contact-information input {
    width: 100%;
  }
}
</style>