<template>
  <div>
    <section class="links">
      <div class="quick-links">
        <h2>Liens utiles</h2>
        <ul>
          <li><router-link to="/faq"> FAQ</router-link></li>
          <li><router-link to="/contact"> Contact</router-link></li>
          <li>
            <router-link to="/Login"> S'inscrire</router-link>
          </li>
          <li><router-link to="/terms"> Termes et conditions</router-link></li>
        </ul>

        <div>
          <h3>Reseaux sociaux</h3>
          <div class="social-medias">
            <p><i class="fab fa-facebook fa-2x"></i></p>
            <p><i class="fab fa-snapchat fa-2x"></i></p>
            <p><i class="fab fa-instagram fa-2x"></i></p>
          </div>
        </div>
      </div>

      <div class="location">
        <h2>Notre adresse</h2>
        <p>25b baker street</p>
        <p>Mon-Fri: 08h00-20h00</p>
        <p>Sat-Sun: 08h00-20h00</p>
        <p>+242-06-000-00-00</p>
      </div>

      <div class="subscribe">
        <h2>Souscrivez</h2>
        <p>
          Souscrivez a notre newsletter pour être régulièrement informé de nos
          offres
        </p>
        <input type="email" placeholder="votre email" />
        <!-- <i class="fas fa-paper-plane"></i> -->
        <button type="submit" class="btn">Envoyer</button>

        <footer>
          <p>Copyright &copy; 2020 Mérite K</p>
        </footer>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* copyright footer */
footer {
  margin-top: 10px;
}

/* links */
.links {
  background-color: var(--bg3);
  color: white;
  display: flex;
  justify-content: space-around;
  height: auto;
  padding: 50px 0;
}

.quick-links ul li,
.subscribe p,
.location p {
  padding-top: 5px;
  font-family: var(--first-police);
}

.links h2 {
  color: var(--color2);
  font-family: var(--first-police);
}

.quick-links {
  width: 30%;
  text-align: center;
}

.quick-links a {
  display: inline-block;
  position: relative;
  line-height: 1.5;
  color: var(--color1);
  font-family: var(--first-police);
  font-size: 17px;
}

.quick-links a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--color2);
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.quick-links a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.quick-links div {
  margin-top: 10px;
}

.location {
  width: 30%;
  text-align: center;
  height: 195px;
  line-height: 2;
}

/* subscribe */
.subscribe {
  width: 30%;
  text-align: center;
  height: 195px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscribe input {
  margin-top: 15px;
  border: none;
  padding: 10px;
  width: 40%;
  border-radius: 5px;
  outline: none;
}

.subscribe button {
  width: 40%;
  margin-top: 10px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  padding: 10px;
  border: 0;
  transition: all 1s;
}

.subscribe button:hover {
  background-color: white;
  color: black;
}

.quick-links div h3 {
  font-family: var(--first-police);
  color: var(--color2);
}

.social-medias {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-medias p i {
  padding: 0px 10px;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .links {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .links h2 {
    text-align: center;
  }

  .quick-links ul li,
  .subscribe p,
  .location p {
    padding-top: 0px;
  }

  .location,
  .subscribe {
    border-left: none;
  }

  .subscribe {
    margin-top: 10px;
  }

  .subscribe input {
    margin-top: 0px;
  }

  .subscribe input,
  .subscribe button {
    width: 80%;
  }
}

@media screen and (max-width: 550px) {
  .subscribe,
  .location,
  .quick-links {
    width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 400px) {
  .links {
    height: auto;
  }
}
</style>