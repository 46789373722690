<template>
  <div>
    <section class="services">
      <h2>Services</h2>
      <div class="command">
        <div data-aos="flip-left" data-aos-duration="1000" data-aos-delay="400">
          <img src="../assets/Services/order-1.svg" alt="" />
          <h3>Commandez votre nourriture</h3>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae
            voluptatum ea dolores tenetur. Molestias nobis ipsum maxime eius!
            Quos fuga recusandae iure voluptatibus sequi deserunt qui, similique
            deleniti sapiente aliquam?
          </p>
        </div>

        <div data-aos="flip-up" data-aos-duration="1000" data-aos-delay="500">
          <img src="../assets/Services/order-2.svg" alt="" />
          <h3>Livraison ou retrait</h3>
          <p>
            Lorem Dipsum dolor sit, amet consectetur adipisicing elit. Molestiae
            voluptatum ea dolores tenetur. Molestias nobis ipsum maxime eius!
            Quos fuga recusandae iure voluptatibus sequi deserunt qui, similique
            deleniti sapiente aliquam?
          </p>
        </div>

        <div
          data-aos="flip-right"
          data-aos-duration="1000"
          data-aos-delay="600"
        >
          <img src="../assets/Services/order-3.svg" alt="" />
          <h3>Reservation en ligne</h3>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae
            voluptatum ea dolores tenetur. Molestias nobis ipsum maxime eius!
            Quos fuga recusandae iure voluptatibus sequi deserunt qui, similique
            deleniti sapiente aliquam?
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  created() {
    AOS.init();
  },
};
</script>

<style scoped>
.services {
  background-color: #191f3a;
}

.services h2 {
  text-align: center;
  font-family: var(--cursive-police);
  color: var(--color2);
  margin-bottom: 20px;
}

.command {
  display: flex;
  justify-content: space-around;

  align-items: center;
  color: var(--color1);
}

.command div {
  width: 25%;
  margin: auto;
  text-align: center;
}

.command div h3 {
  text-transform: capitalize;
  font-family: var(--first-police);
  font-size: 25px;
  margin-bottom: 10px;
}

.command div p{
  font-family: var(--second-police);
}

.command div img {
  max-width: 90px;
  display: block;
  margin: auto;
  cursor: pointer;
  color: var(--color2);
}

.command img:hover {
  color: var(--color1);
}

@media screen and (max-width: 750px) {
  .command {
    display: flex;
    flex-direction: column;
  }

  .command div {
    width: 90%;
  }

  .command div p {
    text-align: justify;
    padding: 0px 60px;
  }
}

@media screen and (max-width: 550px) {
  .command div p {
    padding: 0px 20px;
  }

  .command div{
    margin-top: 10px;
  }
}
</style>