
<template>
  <div>
    <navbar></navbar>
    <section class="profile">
      <div class="row">
        <div class="card-info">
          <div class="card">
            <div>
              <img
                src="../../assets/avatar7.png"
                alt="Admin"
                class="rounded-circle"
                width="150"
              />
              <div class="info">
                <h2 class="name">{{ user.name }}</h2>
                <div>
                  <button>Éditer</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-detail">
          <div class="detail">
            <h3>Full Name</h3>
            <p class="name">{{ user.name }}</p>
          </div>
          <hr />
          <div class="detail">
            <h3>Email</h3>
            <p>{{ user.email }}</p>
          </div>
          <hr />
          <div class="btns">
            <p class="act" @click="choose(1)">Voir les menu</p>

            <p class="act" @click="choose(2)">Voir les reservations</p>

            <p class="act" @click="choose(3)">Voir les commandes</p>

            <p class="act" @click="choose(4)">Créer un admin</p>

            <p class="act" @click="choose(5)">Ajouter un plat</p>

            <p class="act" @click="choose(6)">Ajouter un chef</p>
          </div>
        </div>
      </div>
    </section>

    <allFood v-if="choosePage.food"></allFood>
    <addFood v-if="choosePage.add"></addFood>
    <commande v-if="choosePage.command"></commande>
    <reservation v-if="choosePage.reservation"></reservation>
    <createAdmin v-if="choosePage.admin"></createAdmin>
    <addChef v-if="choosePage.chef"></addChef>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store/index";
import Navbar from "../../components/MyNavbar.vue";
import localforage from "localforage";

// componenets
import Reservation from "../../components/Admin/AllReservation.vue";
import Commande from "../../components/Admin/MyCommande.vue";
import createAdmin from "../../components/Admin/createAdmin.vue";
import addFood from "../../components/Admin/addFood.vue";
import allFood from "../../components/Admin/allFood.vue";
import addChef from "../../components/Admin/addChef.vue";

export default {
  mounted() {
    this.checkConnectedState();
    axios
      .get("food/all")
      .then((response) => {
        this.allFood = response.data;
      })
      .catch((error) => {
        console.log(error.message);
        // this.info = error.message;
      });
  },

  components: {
    Navbar,
    Reservation,
    Commande,
    createAdmin,
    addFood,
    allFood,
    addChef,
  },

  data: () => {
    return {
      choosePage: {
        food: true,
        reservation: false,
        command: false,
        admin: false,
        add: false,
        chef: false,
      },
    };
  },
  methods: {
    // choose page
    choose(a) {
      this.choosePage.food = a === 1 ? true : false;
      this.choosePage.reservation = a === 2 ? true : false;
      this.choosePage.command = a === 3 ? true : false;
      this.choosePage.admin = a === 4 ? true : false;
      this.choosePage.add = a === 5 ? true : false;
      this.choosePage.chef = a === 6 ? true : false;
    },

    checkConnectedState() {
      if (
        Object.keys(store.state.user).length === 0 &&
        store.state.user.constructor === Object
      ) {
        localforage
          .getItem("user")
          .then((userData) => {
            if (userData && userData.data.role_id == 1) {
              store.commit("setCurrentUser", userData);
            } else {
              this.$router.push({ name: "Login" });
            }
          })
          .catch(() => {
            this.$router.push({ name: "Login" });
          });
      }
    },
  },
  computed: {
    user() {
      return store.state.user.data ? store.state.user.data : {};
    },
  },
};
</script>

<style scoped src="../../../public/Css/admin.css">
</style>
