<template>
  <div>
    <Navbar></Navbar>
    <div class="body">
      <div class="content">
        <form>
          <h2>Mot de passe oublié</h2>
          <div class="field">
            <input type="email" id="inputEmail" placeholder="Email" required />
          </div>
          <button type="submit">Envoyer</button>
        </form>
      </div>
    </div>
    <contact></contact>
  </div>
</template>

<script>
import Navbar from "../../components/MyNavbar.vue";
import Contact from "../../components/MyContact.vue";
export default {
  components: {
    Navbar,
    Contact,
  },
};
</script>


<style scoped>
.body {
  display: grid;
  place-items: center;
  background: #dde1e7;
  text-align: center;
  height: 700px;
}

.content {
  width: 330px;
  padding: 40px 30px;
  background: #dde1e7;
  border-radius: 10px;
  box-shadow: -3px -3px 7px #ffffff73, 2px 2px 5px rgba(94, 104, 121, 0.288);
  /* animation: backInDown 0.5s;
  transition: animation 0.5s; */
}

.content .text {
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 35px;
  color: var(--color3);
}

form h2 {
  font-family: var(--first-police);
  text-align: center;
  color: var(--color3);
}

.field {
  height: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.field input {
  padding: 20px;
  margin: 10px auto;
  border-radius: 5px;
  border: 0;
  outline: none;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}

.field input:focus {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #ffffff73;
}

.field input:valid ~ label {
  opacity: 0;
}

button {
  margin: 15px 0;
  width: 70%;
  height: 50px;
  font-size: 18px;
  line-height: 50px;
  font-weight: 600;
  background: #dde1e7;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--color3);
  box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;
}
</style>