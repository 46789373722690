<template>
  <div>
    <loader></loader>
    <router-view />
  </div>
</template>

<script>
import Loader from "./components/MyLoader.vue";

export default {
  components: {
    Loader,
  },
};
</script>

<style>
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bg1: #0c1023;
  --bg2: #121619;
  --bg3: #191f3a;
  --bg4: #e8e8ea;
  --color1: #fff;
  --color2: #eaa023;
  --color3: #595959;
  --color4: #f4f4f4;
  --first-police: "PT Sans", sans-serif;
  --cursive-police: "Pacifico", cursive;
  --second-police: "Open Sans", sans-serif;
  --pad: 50px 0px;
  --title-color: #fa5c5e;
  --border-color: #ebebeb;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--color1);
}

h2 {
  font-family: var(--first-police);
}

section {
  padding: var(--pad);
}

.btn {
  padding: 10px;
  border-radius: 5px;
  border: 0px;
  background-color: var(--color2);
  color: var(--color1);
  text-transform: uppercase;
  font-family: var(--first-police);
  outline: none;
  width: 200px;
  margin: 0px auto;
  display: block;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s;
}

.btn:hover {
  color: var(--color2);
  background-color: var(--color1);
  border: 1px solid var(--color2);
}

::placeholder {
  text-transform: capitalize;
}
</style>
