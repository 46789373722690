<template>
  <div>
    <section class="speciality">
      <h2>Nos spécialités</h2>
      <h3>De la part de Merite Food</h3>
      <div class="speciality-sub">
        <div
          v-for="(speciality, i) in specialities"
          :key="i"
          :data-aos="speciality.animation"
          :data-aos-duration="speciality.duration"
          :data-aos-delay="speciality.delay"
        >
          <img src="@/assets/burger/burger1.png" alt="plat" />

          <p class="pizza-name">{{ speciality.name }}</p>
        </div>
      </div>

      <router-link to="/menu">
        <button class="btn">Voir plus</button></router-link
      >
    </section>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

// axios
import axios from "axios";
export default {
  created() {
    AOS.init();
  },
  mounted() {
    axios
      .get("food/speciality")
      .then((response) => {
        this.specialities = response.data;
      })
      .catch((error) => {
        this.info = error.message;
      });
  },
  data() {
    return {
      specialities: [],
      info: "",
    };
  },
};
</script>

<style scoped>
.speciality {
  background-color: var(--color4);
}

.speciality h2 {
  text-align: center;
  font-family: var(--cursive-police);
  color: var(--color2);
}

.speciality h3 {
  font-family: var(--first-police);
  text-transform: uppercase;
  text-align: center;
  font-size: 50px;
}

.speciality-sub {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.speciality-sub div:nth-child(2) {
  margin-left: 10px;
}

.speciality-sub div:hover img {
  animation: pulse 0.5s;
  transition: all 0.5s;
  animation-delay: 0.1s;
  cursor: pointer;
}

.speciality-sub p {
  font-size: 20px;
  text-transform: capitalize;
  text-align: center;
  font-family: var(--second-police);
  margin-top: 10px;
}

.speciality-sub img {
  width: 200px;
}

.btn {
  margin-top: 10px;
}

.btn:hover a {
  color: var(--color2);
}

/* responsive */
@media screen and (max-width: 800px) {
  .speciality h3 {
    font-size: 18px;
    margin: 10px 0px;
  }
}

@media screen and (max-width: 750px) {
  .speciality-sub {
    flex-direction: column;
  }
}
</style>