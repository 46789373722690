<template>
  <!-- reservations -->
  <section class="reservation">
    <h2>reservation</h2>
    <div>
      <table>
        <tr>
          <th>Id</th>
          <th>Nom</th>
          <th>Email</th>
          <th>Places</th>
          <th>Numero</th>
          <th>ReservationDate</th>
          <th>Validation</th>
          <th>Actions</th>
        </tr>

        <tr v-for="(reservation, i) in reservations" :key="i">
          <td>{{ reservation.id }}</td>
          <td>{{ reservation.name }}</td>
          <td>{{ reservation.email }}</td>
          <td>{{ reservation.place }}</td>
          <td>{{ reservation.number }}</td>
          <td>{{ reservation.reservationDate }}</td>
          <td>{{ reservation.validation }}</td>

          <td class="icons">
            <p class="supprimer"><i class="fas fa-check"></i> Valider</p>
            <p class="supprimer" @click="deleteBooking(reservation.id)">
              <i class="fas fa-trash"></i> supprimer
            </p>
          </td>
        </tr>
      </table>
    </div>
  </section>
  <!-- end reservation -->
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    axios
      .get("reservation/getBooking")
      .then((response) => {
        this.reservations = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  data() {
    return {
      reservations: [],
    };
  },
  methods: {
    deleteBooking(id) {
      let confirm = window.confirm(
        "Voulez-vous vraiment supprimé cette reservation ?"
      );

      if (confirm) {
        axios
          .delete(`reservation/delete/${id}`)
          .then((response) => {
            console.log(response.data);
            alert("ca a marche");
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    },
  },
};
</script>

<style scoped>
/* reservation */
section {
  padding: 20px 0px;
}

.reservation {
  position: relative;
  margin-top: 20px;
  overflow-x: auto;
}

.reservation h2 {
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.reservation table {
  border-collapse: collapse;
  border: 1px solid var(--border-color);
  width: 90%;
  margin: auto;
}

.reservation th,
.reservation td {
  border: 1px solid var(--border-color);
  padding: 15px;
  font-family: var(--second-police);
  font-size: 18px;
  text-align: center;
}

.reservation,
.supprimer {
  cursor: pointer;
}

.icons {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid var(--border-color);
  padding: 15px;
}

.icons p {
  cursor: pointer;
  padding-left: 15px;
  margin-top: 20px;
  text-align: center;
}

.icons p:hover {
  text-decoration: underline;
}
/* end reservation */
</style>