<template>
  <div>
    <navbar></navbar>
    <header data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200">
      <div>
        <h2>FAQ</h2>
        <li><router-link to="/">Accueil </router-link>/Faq</li>
      </div>
    </header>

    <section class="faq">
      <h2>Questions souvent posées</h2>
      <div class="faq-sub">
        <div
          class="questions"
          v-for="(question, i) in questions"
          :key="i"
          @click="question.open = !question.open"
        >
          <div>
            <h3>{{ question.title }}</h3>
            <i class="fas fa-chevron-down"></i>
          </div>
          <p v-if="question.open">{{ question.response }}</p>
        </div>
      </div>
    </section>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "../../components/MyContact.vue";
import Navbar from "../../components/MyNavbar.vue";

import AOS from "aos";
import "aos/dist/aos.css";
export default {
  created(){
    AOS.init();
  },
  components: {
    Contact,
    Navbar,
  },
  data() {
    return {
      questions: [
        {
          title: "Lorem, ipsum dolor.",
          response:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim temporibus atque eius deleniti voluptatibus nobis reprehenderit eum deserunt, similique sit harum, sed at ipsa fugit odit consectetur nisi distinctio quia iusto, ipsum aliquid minima. Beatae!",
          open: false,
        },
        {
          title: "Lorem, ipsum dolor.",
          response:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim temporibus atque eius deleniti voluptatibus nobis reprehenderit eum deserunt, similique sit harum, sed at ipsa fugit odit consectetur nisi distinctio quia iusto, ipsum aliquid minima. Beatae!",
          open: false,
        },
        {
          title: "Lorem, ipsum dolor.",
          response:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim temporibus atque eius deleniti voluptatibus nobis reprehenderit eum deserunt, similique sit harum, sed at ipsa fugit odit consectetur nisi distinctio quia iusto, ipsum aliquid minima. Beatae!",
          open: false,
        },
        {
          title: "Lorem, ipsum dolor.",
          response:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim temporibus atque eius deleniti voluptatibus nobis reprehenderit eum deserunt, similique sit harum, sed at ipsa fugit odit consectetur nisi distinctio quia iusto, ipsum aliquid minima. Beatae!",
          open: false,
        },
        {
          title: "Lorem, ipsum dolor.",
          response:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim temporibus atque eius deleniti voluptatibus nobis reprehenderit eum deserunt, similique sit harum, sed at ipsa fugit odit consectetur nisi distinctio quia iusto, ipsum aliquid minima. Beatae!",
          open: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
header {
  background-image: url("~@/assets/blog-7.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;

  align-items: center;
}

header div {
  color: var(--color1);
  font-size: 20px;
  font-family: var(--first-police);
}

header div h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--first-police);
  text-align: center;
}

.faq {
  height: auto;
  background: #e8e8ea;
}

.faq h2 {
  text-align: center;
  margin-bottom: 10px;
}

.faq-sub {
  width: 75%;
  margin: auto;
  padding: 20px;
  background: var(--color1);
  border-radius: 5px;
}

.questions div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
  font-family: var(---police);
  font-weight: 700;
  margin: 10px 0px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.questions div:hover,
.questions div:active {
  color: var(--title-color);
  border-color: var(--title-color);
}

.questions p {
  font-size: 15px;
  font-family: var(--second-police);
  line-height: 1.3;
}

/* responsive */
@media screen and (max-width: 550px) {
  .faq-sub{
    width: 85%;
  }
}
</style>