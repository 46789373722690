<template>
  <!-- start ajout formulaire -->
  <section class="addModale">
    <h2> Creer un chef</h2>
    <form @submit.prevent="addChef">

      <input
        type="text"
        placeholder="mettez un nom"
        required
        v-model="createChef.name"
      />

      <input
        type="text"
        placeholder="mettez un role"
        v-model="createChef.role"
        required
      />

      <input
        type="file"
        @change="getImage"
        name="image"
        id="image"
        accept="image/*"
        required
      />
      <button class="btn_modale">Ajouter</button>
    </form>
  </section>
  <!--  end ajout formulaire -->
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      createChef: {
        name: null,
        role: null,
      },
      image: null,
    };
  },
  methods: {
    getImage(event) {
      this.image = event.target.files[0];
    },
    // create vhef
    addChef() {
      let fd = new FormData();

      fd.append("image", this.image);
      axios
        .post("/uploads", fd)
        .then((response) => {
          axios
            .post("chef/create", {
              ...this.createChef,
              image: response.data.url,
            })
            .then((response) => {
              const { data } = response;
              if (data.statut == "success") {
                alert(data.message);
              } else {
                alert(data.message);
              }
            })
            .catch((error) => {
              alert(error);
              console.log(error.message);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
/*start add food */
section {
  padding: 20px 0px;
}

.addModale {
  color: #333;
}

.addModale h2 {
  text-align: center;
  margin-bottom: 10px;
}

.addModale form {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: #e9ecef;
  border-radius: 10px;
  box-shadow: -3px -3px 7px #ffffff73, 2px 2px 5px rgba(94, 104, 121, 0.288);
  width: 50%;
  margin: auto;
}

.addModale form input {
  background: #eee;
  padding: 16px;
  margin: 8px 0;
  width: 85%;
  border: 0;
  outline: none;
  border-radius: 5px;
  box-shadow: inset 7px 2px 10px #babebc, inset -5px -5px 12px #fff;
}

/* end add food */
</style>