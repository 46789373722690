<template>
  <div>
    <Navbar></Navbar>
    <header>
      <div>
        <h2>Paiement</h2>
        <li>
          <router-link to="/">Accueil /</router-link>
          <router-link to="/order"> Panier </router-link>/ Paiement
        </li>
      </div>
    </header>
    <section class="checkout">
      <div class="account" v-if="register">
        <div class="title">
          <h2>Créer un compte</h2>
          <p @click="haveAccount">J'ai deja un compte</p>
        </div>

        <form class="form" @submit.prevent="createMember" v-if="createAccount">
          <input
            type="text"
            name="name"
            id="name"
            placeholder="ton nom"
            required
            v-model="create.name"
          />

          <input
            type="email"
            name="email"
            id="email"
            placeholder="ton mail"
            required
            v-model="create.email"
          />

          <input
            type="password"
            name="password"
            id="password"
            placeholder="Choisir un mot de passe"
            required
            v-model="create.password"
          />
          <button class="btn">Créer un compte</button>
        </form>
        <p v-if="account" class="message">{{ reponse }}</p>
      </div>
      <div class="account" v-if="login">
        <div class="title">
          <h2>Se connecter</h2>
          <p @click="haveAccount">Créer un compte</p>
        </div>
        <form class="form" @submit.prevent="setConnexion" v-if="getAccount">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="ton mail"
            required
            v-model="connexion.email"
          />

          <input
            type="password"
            name="password"
            id="password"
            placeholder="ton mot de passe"
            required
            v-model="connexion.password"
          />
          <button class="btn">Se connecter</button>
        </form>
        <p v-if="account" class="message">{{ reponse }}</p>
      </div>
      <div class="order">
        <h2>Votre panier</h2>
        <div class="order-sub" v-for="(panier, i) in paniers" :key="i">
          <div class="details">
            <p>Nom: {{ panier.name }}</p>
            <p>Prix: {{ panier.price }} Fcfa</p>
          </div>
        </div>
      </div>
    </section>

    <section class="achat" v-if="billing">
      <h2>Details de facturation</h2>
      <form @submit.prevent="sendBilling" v-if="billForm">
        <div class="label">
          <label for="name"> Ton nom</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="votre nom"
            required
            v-model="bill.name"
          />
        </div>
        <div class="label">
          <label for="nickname"> Ton prénom</label>
          <input
            type="text"
            name="nickname"
            id="nickname"
            placeholder="votre prenom"
            required
            v-model="bill.prenom"
          />
        </div>
        <div class="label">
          <label for="addresse"> Ton adresse</label>
          <input
            type="text"
            name="addresse"
            id="addresse"
            placeholder="votre adresse"
            required
            v-model="bill.adresse"
          />
        </div>
        <div class="label">
          <label for="name"> Ton numero de telephone</label>
          <input
            type="tel"
            name="tph"
            id="tph"
            placeholder="votre numero de telephone ex: 06 777 77 77"
            required
            v-model="bill.tel"
          />
        </div>
        <div class="label">
          <input type="hidden" required v-model="bill.user_id" />
        </div>
        <button class="btn">Enregistrer et continuer</button>
      </form>
      <p v-if="account" class="message">{{ billResponse }}</p>
    </section>

    <section class="payment" v-if="pay">
      <h2>Details de paiement</h2>
      <form>
        <div class="label">
          <label for="name"> Ton nom</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="votre nom"
            required
          />
        </div>
        <div class="label">
          <label for="nickname"> Ton prénom</label>
          <input
            type="text"
            name="nickname"
            id="nickname"
            placeholder="votre prenom"
            required
          />
        </div>
        <div class="label">
          <label for="addresse"> Ton adresse</label>
          <input
            type="text"
            name="addresse"
            id="addresse"
            placeholder="votre adresse"
            required
          />
        </div>

        <button class="btn" @click="buy">Enregistrer et continuer</button>
      </form>
    </section>
    <section >
      <div class="price">
        <div>
          <p>Jour de livraison :</p>
          <p>33 février 2099</p>
        </div>
        <hr />
        <div>
          <p>Total :</p>
          <p>{{ priceTotal }} Fcfa</p>
        </div>
        <hr />
        <div>
          <p>Payment:</p>
          <p>Mtn</p>
        </div>
        <hr />
        <div>
          <p>Nombre d’éléments</p>
          <p>{{ items }}</p>
        </div>
        <hr />
        <div>
          <p>N° order:</p>
          <p>2233</p>
        </div>
      </div>

      <button class="btn" @click="sendCommand">Commander</button>
    </section>
    <contact></contact>
  </div>
</template>

<script>
import axios from "axios";
import Contact from "../../components/MyContact.vue";
import Navbar from "../../components/MyNavbar.vue";
import store from "../../store";
import localforage from "localforage";
export default {
  async mounted() {
    localforage.getItem("user").then((userData) => {
      if (userData) {
        let id = userData.data.id;
        axios.get(`auth/one/${id}`).then((response) => {
          this.reponse = "deja enregistre";
          this.account = true;
          this.createAccount = false;
          this.getAccount = false;
          this.billing = true;
          if (response.data.data) {
            console.log(response.data);
            this.bill = response.data;
            this.billResponse = "profil complet";
            this.billForm = false;
            this.pay = true;
          }
        });
      }
    });

    if (
      Object.keys(store.state.panier).length === 0 &&
      store.state.panier.constructor === Array
    ) {
      localforage
        .getItem("food")
        .then((food) => {
          if (food) {
            store.dispatch("ajoutPanier", food);
          }
        })
        .catch(() => {
          this.$router.push({ name: "Login" });
        });
    }
  },
  components: {
    Contact,
    Navbar,
  },
  data() {
    return {
      create: {
        name: null,
        email: null,
        password: null,
      },
      billResponse: "",
      bill: {
        name: null,
        prenom: null,
        adresse: null,
        tel: null,
        user_id: null,
      },
      reponse: "",
      account: false,
      createAccount: true,
      getAccount: true,
      billing: false,
      billForm: true,
      pay: false,
      price: false,
      connexion: {
        email: null,
        password: null,
      },
      paiement: {
        email: null,
        password: null,
      },
      register: true,
      login: false,
    };
  },

  methods: {
    haveAccount() {
      this.register = this.register ? false : true;
      this.login = this.login === false ? true : false;
    },
    createMember() {
      axios
        .post("auth/signup", {
          ...this.create,
        })
        .then((response) => {
          if (response.data.status == "reussite") {
            this.reponse = response.data.message;
            this.account = true;
            this.createAccount = false;
            this.billing = true;
          } else {
            this.reponse = response.data.message;
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    setConnexion() {
      axios
        .post("auth/signin", { ...this.connexion })
        .then((response) => {
          if (response.data.status == "success") {
            this.reponse = response.data.message;
            this.account = true;
            this.getAccount = false;
            this.billing = true;
            localforage
              .setItem("user", response.data)
              .then((userData) => {
                store.commit("setCurrentUser", userData);
              })
              .catch((err) => {
                console.log(err);
              });
            if (response.data.contact) {
              this.bill = response.data;
              this.billResponse = "profil complet";
              this.billForm = false;
              this.pay = true;
            }
          }
        })
        .catch((error) => {
          this.reponse = error.response.data.message;
          this.account = true;
        });
    },
    sendBilling() {
      this.bill.user_id = Object.values(store.state.user)[3].id;
      axios
        .post("/contact", { ...this.bill })
        .then((response) => {
          if (response.data.status == "reussite") {
            this.pay = true;
            this.billResponse = response.data.message;
            this.account = true;
            this.billForm = false;
          }
        })
        .catch((error) => {
          this.billResponse = error.response.data.message;
          this.account = true;
        });
    },
    buy() {
      this.price = true;
    },
    sendCommand() {
      let date = new Date().toISOString().slice(0, 10);

      let order = {};

      if (store.state.panier) {
        this.paniers.map((food) => {
          console.log(food);
          order = {
            food_id: food.id,
            user_id: Object.values(store.state.user)[3].id,
            price: food.price,
            commandeDate: date,
            number: 1,
          };
          console.log(order);
          axios
            .post("commande/order", { ...order })
            .then(() => {
              alert("commande effectue avec succes");
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
    },
  },
  computed: {
    priceTotal() {
      return store.getters.priceTotal;
    },
    items() {
      let item = store.state.panier ? store.state.panier.length : 0;
      return item;
    },
    paniers: () => store.state.panier,
  },
};
</script>

<style scoped>
header {
  background-image: url("~@/assets/blog-8.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

header div {
  color: var(--color1);
  font-size: 20px;
  font-family: var(--first-police);
}

header div h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--first-police);
  text-align: center;
}

header div li {
  text-align: center;
}

/* checkout */
.checkout {
  display: flex;
  justify-content: space-around;
}

.order {
  width: 25%;
  background: var(--color4);
}

.account {
  border: 1px solid var(--border-color);
  width: 60%;
  border-radius: 5px;
  padding: 10px;
}

.message {
  text-align: center;
  font-size: 20px;
  text-transform: capitalize;
  font-family: var(--second-police);
  color: var(--color2);
  margin-top: 50px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.title h2,
.title p {
  font-family: var(--first-police);
  color: var(--color3);
  font-size: 20px;
  margin: 5px 10px;
}

.title p {
  cursor: pointer;
}

.account form {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account form input {
  padding: 5px;
  outline: none;
  width: 40%;
  margin-top: 10px;
}

.account .input {
  display: flex;
  justify-content: space-between;
}

.account .form .btn {
  margin-top: 10px;
  text-align: center;
}

/* order */
.order h2 {
  text-align: center;
  color: var(--color2);
}

.order-sub {
  display: flex;
  border-bottom: 1px solid var(--color3);
  margin: 10px auto;
}

.order-sub p {
  text-transform: capitalize;
  font-size: 20px;
  font-family: var(--second-police);
}

.details {
  margin-left: 20px;
  line-height: 1.5;
}

.btn {
  margin-top: 10px;
}

/* billing details */
.achat {
  width: 60%;
  border: 1px solid var(--border-color);
  padding: 10px;
  margin: 0px 0px 20px 50px;
}

.achat h2 {
  border-bottom: 1px solid var(--border-color);
  padding: 5px 0px;
  text-align: center;
  font-family: var(--second-police);
}

.achat form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.achat .label {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.label input {
  width: 100%;
  margin: 5px 0px;
  padding: 5px;
  outline: none;
}

.label label {
  text-align: center;
  margin-top: 5px;
  font-family: var(--second-police);
  font-size: 14px;
}

/* paiement details */
.payment {
  width: 60%;
  border: 1px solid var(--border-color);
  padding: 10px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.payment h2 {
  border-bottom: 1px solid var(--border-color);
  padding: 5px 0px;
  text-align: center;
  font-family: var(--second-police);
}

.payment form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment .label {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.payment input {
  width: 100%;
  margin: 5px 0px;
  padding: 5px;
  outline: none;
}

.payment label {
  text-align: center;
  margin-top: 5px;
  font-family: var(--second-police);
  font-size: 14px;
}

/* price */
.price {
  width: 40%;
  margin: auto;
  background: var(--color4);
  padding: 20px;
}

.price div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 5px 0px;
}

/* responsive */

@media screen and (max-width: 820px) {
  .checkout {
    flex-direction: column-reverse;
  }

  .account {
    width: 80%;
    margin: auto;
  }

  .order,
  .payment,
  .achat {
    width: 80%;
    margin: 10px auto;
  }
}

@media screen and (max-width: 700px) {
  .price {
    width: 80%;
  }

  .achat .label,
  .payment .label {
    width: 80%;
  }

  .account form input {
    width: 80%;
  }
}
</style>