<template>
  <div>
    <Navbar />

    <div class="form">
      <form @submit.prevent="createMember">
        <h2>S'inscrire</h2>
        <div class="field">
          <input
            type="text"
            name="name"
            placeholder="Nom d'utilisateur"
            required
            id="name"
            v-model="create.name"
          />
        </div>
        <div class="field">
          <input
            type="email"
            name="email"
            placeholder="Votre email"
            required
            id="email"
            v-model="create.email"
          />
        </div>

        <div class="field">
          <input
            :type="passwordFieldType"
            name="password"
            placeholder="Mot de passe"
            required
            id="password"
            v-model="create.password"
          />
          <i class="fas fa-eye" v-if="icon1" @click="showPassword"></i>
          <i class="fas fa-eye-slash" v-if="icon2" @click="showPassword"></i>
        </div>

        <button>S'inscrire</button>
        <div class="sign-up">
          Êtes-vous inscrit?<router-link to="/login"> Se connecter</router-link>
        </div>
      </form>
    </div>
    <contact></contact>
  </div>
</template>

<script>
import axios from "axios";

import Navbar from "../../components/MyNavbar.vue";
import Contact from "../../components/MyContact.vue";
export default {
  components: {
    Contact,
    Navbar,
  },
  data() {
    return {
      create: {
        name: null,
        email: null,
        password: null,
      },
      remember: false,
      icon1: true,
      icon2: false,
      password: "",
      passwordFieldType: "password",
      response: "",
    };
  },
  methods: {
    showPassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.icon1 = this.icon1 ? false : true;
      this.icon2 = this.icon2 === false ? true : false;
    },
    createMember() {
      axios
        .post("auth/signup", {
          ...this.create,
        })
        .then((response) => {
          if (response.data.status == "reussite") {
            this.$router.push({ name: "userProfile" });
          } else {
            this.response = "badddddddddd";
          }
        })
        .catch((error) => {
          this.info = error.message;
        });
    },
  },
};
</script>


<style scoped>
.form {
  display: grid;
  place-items: center;
  background: #dde1e7;
  text-align: center;
  height: auto;
  position: relative;
  border-radius: 10px;
}

/* form {
  animation: backInDown 0.5s;
  transition: animation 0.5s;
} */

form {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 100px auto;
  padding: var(--pad);
  box-shadow: -3px -3px 7px #ffffff73, 2px 2px 5px rgba(94, 104, 121, 0.288);
  position: relative;
}

form h2 {
  text-align: center;
  color: #595959;
}

form input {
  padding: 15px;
  width: 40%;
  margin: 10px auto;
  border-radius: 5px;
  border: 0;
  outline: none;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}

.field i {
  position: absolute;
  cursor: pointer;
  bottom: 185px;
  right: 270px;
}

button {
  width: 40%;
  margin: 10px auto;
  height: 50px;
  font-size: 18px;
  line-height: 50px;
  font-weight: 600;
  background: #dde1e7;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--color3);
  box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;
}

button:focus {
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}

.sign-up {
  margin: 10px 0;
  color: #595959;
  font-size: 16px;
  text-align: center;
  font-family: var(--second-police);
}

.sign-up a {
  color: #3498db;
  text-decoration: none;
}

.sign-up a:hover {
  text-decoration: underline;
}

/* responsive */
@media screen and (max-width: 750px) {
  form {
    width: 100%;
  }
  form input {
    width: 60%;
  }
}

@media screen and (max-width: 450px) {
  form input {
    width: 80%;
  }

  button {
    width: 80%;
  }
}
</style>