<template>
  <div>
    <navbar></navbar>
    <div class="row">
      <div class="card-info">
        <div class="card">
          <div>
            <img
              src="../../assets/avatar7.png"
              alt="Admin"
              class="rounded-circle"
              width="150"
            />
            <div class="info">
              <h2>{{ user.name }}</h2>
              <div>
                <button @click="myEdit">Editer</button>
                <button @click="profil">Completer mon profil</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-detail">
        <div class="detail">
          <h3>Full Name</h3>
          <p class="name">{{ user.name }}</p>
        </div>
        <hr />
        <div class="detail">
          <h3>Email</h3>
          <p>{{ user.email }}</p>
        </div>
        <hr />
        <div class="boutons">
          <router-link class="act" to="/"> Home</router-link>
          <router-link class="act" to="/menu"> Menu</router-link>
          <router-link class="act" to="/booking"> Reservation</router-link>
          <button class="act" @click="myOrder">Voir mes commandes</button>
        </div>
      </div>
    </div>

    <div class="contact" v-if="profile">
      <h2>Information supplémentaire</h2>
      <form @submit.prevent="sendData">
        <div class="label">
          <label for="name"> Ton nom</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="votre nom"
            required
            v-model="userData.name"
          />
        </div>
        <div class="label">
          <label for="nickname"> Ton prenom</label>
          <input
            type="text"
            name="nickname"
            id="nickname"
            placeholder="votre prenom"
            required
            v-model="userData.prenom"
          />
        </div>
        <div class="label">
          <label for="addresse"> Ton addresse</label>
          <input
            type="text"
            name="addresse"
            id="addresse"
            placeholder="votre adresse"
            required
            v-model="userData.adresse"
          />
        </div>
        <div class="label">
          <label for="name"> Ton numero de telephone</label>
          <input
            type="tel"
            name="tph"
            id="tph"
            placeholder="votre numero de telephone ex: 06 777 77 77"
            required
            v-model="userData.tel"
          />
        </div>
        <div class="label"></div>
        <button class="btn">Enregistrer et continuer</button>
      </form>
    </div>

    <div class="contact" v-if="edit">
      <h2>Modifier mon profil</h2>
      <form @submit.prevent="editProfile">
        <div class="label">
          <label for="name"> Nouveau nom d’utilisateur</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="votre nom"
            required
            v-model="newProfil.username"
          />
        </div>
        <div class="label">
          <label for="nickname"> Email</label>
          <input
            type="text"
            name="nickname"
            id="nickname"
            placeholder="votre email"
            required
            v-model="newProfil.email"
          />
        </div>
        <div class="label">
          <label for="addresse"> Mot de passe</label>
          <input
            type="text"
            name="addresse"
            id="addresse"
            placeholder="votre nouveau mot de passe"
            required
            v-model="newProfil.password"
          />
        </div>

        <div class="label"></div>
        <button class="btn">Envoyer</button>
      </form>
    </div>

    <section class="order" v-if="order">
      <h2>Mes commandes</h2>
      <div>
        <table>
          <tr>
            <th>Nom</th>
            <th>Prix</th>
            <th>Image</th>
          </tr>
          <tr v-for="(commande, i) in commandes" :key="i">
            <td>{{ commande.name }}</td>
            <td>{{ commande.price }}</td>
            <td>
              <img
                :src="`http://127.0.0.1:8000${commande.image}`"
                width="100"
                alt="plat"
              />
            </td>
          </tr>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
// components
import Navbar from "../../components/MyNavbar.vue";
// import axios from "axios";
import store from "../../store/index";
import localforage from "localforage";
import axios from "axios";
export default {
  mounted() {
    if (
      Object.keys(store.state.user).length === 0 &&
      store.state.user.constructor === Object
    ) {
      localforage
        .getItem("user")
        .then((userData) => {
          if (userData && userData.data.role_id == 2) {
            store.commit("setCurrentUser", userData);
          } else {
            this.$router.push({ name: "Login" });
          }
        })
        .catch(() => {
          this.$router.push({ name: "Login" });
        });
    }
    // this.commande();
  },
  components: {
    Navbar,
  },
  data() {
    return {
      profile: false,
      commandes: [],
      order: false,
      edit: false,
      userData: {
        name: null,
        prenom: null,
        adresse: null,
        tel: null,
        user_id: null,
      },
      newProfil: {
        username: null,
        email: null,
        password: null,
      },
    };
  },
  methods: {
    profil() {
      return (this.profile = true);
    },
    sendData() {
      this.userData.user_id = Object.values(store.state.user)[3].id;
      axios
        .post("/contact", { ...this.userData })
        .then((response) => {
          if (response.data.status == "reussite") {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // commande() {
    //   localforage.getItem("user").then((userData) => {
    //     let id = userData.data.id;
    //     axios.get(`/commande/myOrder/${id}`).then((response) => {
    //       console.log(response.data.data);
    //       // this.commandes = response.data.data;
    //     });
    //   });
    // },

    editProfile() {
      let id = Object.values(store.state.user)[3].id;

      axios
        .put(`auth/update/${id}`, {
          ...this.newProfil,
        })
        .then((response) => {
          console.log(response.data);
          alert("effectue");
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    myOrder() {
      return (this.order = true);
    },
    myEdit() {
      return (this.edit = true);
    },
  },
  computed: {
    user() {
      return store.state.user.data ? store.state.user.data : {};
    },
  },
};
</script>

<style scoped>
.row {
  padding: 15px;
  background-color: #e9ecef;
  min-height: 80vh;
  padding: 100px 50px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  align-items: center;
}

.card-info {
  width: 30%;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.card-detail {
  width: 50%;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 20px;
  line-height: 25px;
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.boutons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  flex-wrap: wrap;
}

/* detail */
.detail {
  display: flex;
  padding: 20px 0px;
}

.detail p {
  margin-left: 50px;
  color: var(--colro3);
}

.detail h3 {
  color: #242a35;
  font-family: var(--first-police);
}

/* info */
.info {
  line-height: 1.5;
}

.info h2,
.name {
  text-align: center;
  font-family: var(--second-police);
  text-transform: capitalize;
}

.info div button,
.act {
  padding: 10px;
  background-color: var(--color2);
  color: var(--color1);
  border-radius: 5px;
  border: 0px;
  outline: none;
  cursor: pointer;
}

.act {
  width: 200px;
  text-align: center;
  margin-top: 10px;
}

.info div button:last-of-type {
  margin-left: 10px;
}

.info div button:hover {
  color: var(--color2);
  background-color: var(--color1);
  border: 1px solid var(--color2);
}

.act:hover {
  color: var(--color2);
  background-color: var(--color1);
  border: 1px solid var(--color2);
}

/* completer le profil */
.contact {
  width: 60%;
  border: 1px solid var(--border-color);
  padding: 10px;
  margin: 30px auto;
}

.contact h2 {
  border-bottom: 1px solid var(--border-color);
  padding: 5px 0px;
  text-align: center;
  font-family: var(--second-police);
}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact .label {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.label input {
  width: 100%;
  margin: 5px 0px;
  padding: 5px;
  outline: none;
}

.label label {
  text-align: center;
  margin-top: 5px;
  font-family: var(--second-police);
  font-size: 14px;
}

/* my order */
.order {
  position: relative;
  margin-top: 20px;
  overflow-x: auto;
  padding: 20px 0px;
}

.order h2 {
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.order > p {
  text-align: center;
  font-size: 20px;
  text-transform: capitalize;
  color: var(--title-color);
  font-family: var(--second-police);
}

.order table {
  border-collapse: collapse;
  border: 1px solid var(--border-color);
  width: 90%;
  margin: auto;
}

.order th,
.order td {
  border: 1px solid var(--border-color);
  padding: 15px;
  font-family: var(--second-police);
  font-size: 18px;
  text-align: center;
}

/* responsive */
@media screen and (max-width: 700px) {
  .row {
    flex-direction: column;
  }

  .card-info,
  .card-detail {
    width: 80%;
    margin: 10px auto;
  }
}

@media screen and (max-width: 500px) {
  .boutons {
    align-items: center;
    flex-direction: column;
  }

  .act {
    margin-top: 10px;
  }
}

@media screen and (max-width: 450px) {
  .card-info,
  .card-detail {
    width: 100%;
    margin: 10px auto;
  }

  .row {
    padding: 100px 30px;
  }
}
</style>