<template>
  <!-- start creer un admin -->
  <section  class="body">
    <div class="content">
      <form @submit.prevent="createAdmin">
        <h2>Creer un admin</h2>
        <div class="field">
          <input
            type="text"
            name="name"
            placeholder="Nom d'utilisateur"
            required
            id="name"
            v-model="create.name"
          />
        </div>

        <div class="field">
          <input
            type="email"
            name="email"
            placeholder="Votre email"
            required
            id="email"
            v-model="create.email"
          />
        </div>

        <div class="field">
          <input
            :type="passwordFieldType"
            v-model="create.password"
            id="inputPassword"
            placeholder="Password"
            required
          />
          <i class="fas fa-eye" v-if="icon1" @click="showPassword"></i>
          <i class="fas fa-eye-slash" v-if="icon2" @click="showPassword"></i>
        </div>

        <button type="submit">Creer</button>
      </form>
    </div>
  </section>
  <!-- end creer un admin -->
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      password: "",
      passwordFieldType: "password",
      icon1: true,
      icon2: false,
      create: {
        name: null,
        email: null,
        password: null,
      },
    };
  },
  methods: {
    // password
    showPassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.icon1 = this.icon1 === true ? false : true;
      this.icon2 = this.icon2 === false ? true : false;
    },

    // create admin
    createAdmin() {
      axios
        .post("auth/admin", {
          ...this.create,
        })
        .then((response) => {
          if (response.data.statut == "reussite") {
            console.log(response.data.message);
          } else {
            this.response = "badddddddddd";
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.info = error.message;
        });
    },
  },
};
</script>

<style scoped>
/* start admin */
section {
  padding: 20px 0px;
}

.body {
  display: grid;
  place-items: center;
  text-align: center;
}

.content {
  width: 330px;
  padding: 40px 30px;
  background: #e9ecef;
  border-radius: 10px;
  box-shadow: -3px -3px 7px #ffffff73, 2px 2px 5px rgba(94, 104, 121, 0.288);
}

.content .text {
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 35px;
  color: var(--color3);
}

.content form h2 {
  font-weight: 600;
  text-align: center;
  color: var(--color3);
  font-size: 25px;
}

.field {
  height: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.field i {
  position: absolute;
  right: 55px;
  cursor: pointer;
  top: 25px;
}

.field:nth-child(2) {
  margin-top: 20px;
}

.field input {
  padding: 20px;
  margin: 10px auto;
  border-radius: 5px;
  border: 0;
  outline: none;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}

.field input:focus {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #ffffff73;
}

.field label {
  position: absolute;
  transform: translateY(-50%);
  left: 35px;
  color: var(--color3);
}

.field input:valid ~ label {
  opacity: 0;
}

.content button {
  margin: 15px 0;
  width: 70%;
  height: 50px;
  font-size: 18px;
  line-height: 50px;
  font-weight: 600;
  background: #dde1e7;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--color3);
  box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;
}

.content button:focus {
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}

/* end admin */
</style>