<template>
  <div>
    <nav id="nav" class="nav">
      <div class="logo"><router-link to="/">Merite food </router-link></div>

      <div class="cart">
        <router-link to="/order">
          <i class="fas fa-shopping-basket">
            <span> {{ items }} </span></i
          ></router-link
        >
      </div>

      <button type="button" @click="showMenu" class="nav-toggler">
        <span></span>
      </button>

      <div class="liens" v-if="navVisible">
        <li>
          <router-link to="/"> Accueil</router-link>
        </li>

        <li>
          <router-link to="/booking"> Reservation</router-link>
        </li>

        <li>
          <router-link to="/contact"> Contact</router-link>
        </li>

        <li>
          <router-link to="/menu"> Menu</router-link>
        </li>

        <li>
          <router-link to="/aboutus"> A propos</router-link>
        </li>

        <li>
          <a @click="profile"> Profil</a>
        </li>

        <li v-if="disconnect">
          <a @click="Disconnect"> Se déconnecter</a>
        </li>
        <li v-if="register">
          <router-link to="/Login"> Se connecter</router-link>
        </li>
      </div>
    </nav>
  </div>
</template> 

<script>
import localforage from "localforage";
import store from "../store/index";
export default {
  mounted() {
    if (Object.keys(store.state.user).length === 0) {
      localforage.getItem("user").then((userData) => {
        if (userData) {
          store.commit("setCurrentUser", userData);
          this.register = false;
          this.disconnect = true;
        }
      });
    } else {
      this.register = false;
      this.disconnect = true;
    }
    window.addEventListener("scroll", () => {
      let nav = document.getElementById("nav");
      if (window.pageYOffset > 80) {
        nav.style.backgroundColor = "#191F3A";
      }
    });
  },
  data() {
    return {
      panier: [],
      navVisible: false,
      showModal: false,
      disconnect: false,
      register: true,
    };
  },
  methods: {
    Disconnect() {
      localforage.removeItem("user");
      store.commit("setCurrentUser", {});
      this.$router.push({ name: "Login" });
    },
    showMenu() {
      this.navVisible = this.navVisible == false ? true : false;
    },
    profile() {
      localforage.getItem("user").then((userData) => {
        if (userData && userData.data.role_id == 1) {
          this.$router.push({ name: "Admin" });
        } else if (userData && userData.data.role_id == 2) {
          this.$router.push({ name: "userProfile" });
        } else {
          this.$router.push({ name: "Login" });
        }
      });
    },
  },
  computed: {
    items() {
      let item = store.state.panier ? store.state.panier.length : 0;
      return item;
    },
  },
};
</script>

<style scoped>
nav {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  font-size: 20px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 55px;
  font-family: var(--first-police);
  background: var(--bg3);
}

nav::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  z-index: -1;
  transition: transform 0.5s ease;
  transform: translateY(-100%);
}

nav.sticky::before {
  transform: translateY(0%);
}

.logo {
  margin-left: 10px;
  color: var(--color1);
}

.cart {
  cursor: pointer;
  color: var(--color1);
  position: relative;
}

.cart i span {
  position: absolute;
  border: 1px solid var(--color2);
  border-radius: 50%;
  padding: 0px 5px;
  top: -5px;
  background: var(--color2);
}

.liens {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 280px;
  background-color: var(--bg3);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  padding: 80px 0 40px;
  transition: all 0.5s ease;
}

.liens li {
  padding: 10px;
  color: var(--color1);
}

.liens li:hover {
  animation: pulse 0.5s;
  transition: all 0.5s;
}

.nav-toggler {
  height: 34px;
  width: 44px;
  margin-right: 15px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.nav-toggler.active {
  position: absolute;
  z-index: 1;
  transition: all 0.5s ease;
  transform: translateX(-200px);
  right: 0;
}

.nav-toggler span {
  height: 2px;
  width: 30px;
  display: block;
  background-color: var(--color1);
  position: relative;
}

.nav-toggler.active span {
  background-color: var(--color1);
  transition: background-color 0.5s ease;
}

.nav-toggler span::before,
.nav-toggler span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color1);
}

.nav-toggler span::before {
  transform: translateY(-8px);
}

.nav-toggler.active span::before {
  transform: rotate(45deg);
  transition: transform 0.5s ease;
}

.nav-toggler span::after {
  transform: translateY(8px);
}

.nav-toggler.active span::after {
  transform: rotate(-45deg);
  transition: transform 0.5s ease;
}

@media screen and (max-width: 1150px) {
  .liens {
    width: 200px;
  }

  .nav-toggler.active {
    transform: translateX(-100px);
  }
}
</style>