<template>
  <div>
    <navbar></navbar>
    <header>
      <div>
        <p>FAQ</p>
      </div>

      <div>
        <li><router-link to="/">Accueil > </router-link></li>

        <li>
          <p class="link">FAQ</p>
        </li>
      </div>
    </header>

    <section class="terms">
      <h2>Terms and confitions</h2>
    </section>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "../../components/MyContact.vue";
import Navbar from "../../components/MyNavbar.vue";
export default {
  components: {
    Contact,
    Navbar,
  },
};
</script>

<style scoped>
header {
  background-image: url("~@/assets/Contact/about-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header div {
  color: var(--color1);
  font-size: 20px;
  font-family: var(--first-police);
  display: flex;
}

header div:nth-child(1) {
  margin-left: 100px;
}

header div:nth-child(2) {
  margin-right: 100px;
}

.link {
  color: var(--title-color);
  font-size: 18px;
}

header div li a {
  color: var(--bg1-color);
  font-size: 20px;
  font-family: var(--first-police);
  padding: 10px;
}

</style>