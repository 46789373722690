import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/MyHome.vue'

// admin
import Admin from '../views/admin/MyAdmin.vue'


// commande
import Order from '../views/Commande/MyOrder.vue'
import Booking from '../views/Commande/MyBooking.vue'
import Menu from '../views/Commande/MyMenu.vue'
import Checkout from '../views/Commande/MyCheckout.vue'

// test
import test from '../views/test/MyTest.vue'
import test2 from '../views/test/MyTest2.vue'
import Test3 from '../views/test/MyTest3.vue'
import test4 from '../views/test/MyTest4.vue'
import test5 from '../views/test/MyTest5.vue'

// Lien utiles
import Faq from '../views/Liens/MyFaq.vue'
import Terms from '../views/Liens/MyTerms.vue'

// inscription
import Login from '../views/Inscription/UserLogin.vue'
import Register from '../views/Inscription/UserRegister.vue'
import resetPwd from '../views/Inscription/resetPwd.vue'
import userProfile from '../views/Inscription/userProfile.vue'

// pages
import AboutUs from '../views/Pages/AboutUs.vue'
import Contact from '../views/Pages/OurContact.vue'

// food
import detail from '../views/Food-Detail/DetailFood.vue'

// 404
import NotFound from '../views/404/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  },

  // inscription
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/reset',
    name: 'reset',
    component: resetPwd,
  },
  {
    path: '/userProfile/',
    name: 'userProfile',
    component: userProfile,
  },
  
  // admin
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
  },
  // services
  {
    path: '/order',
    name: 'Order',
    component: Order,
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking,
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
  },

  // test
  {
    path: '/test',
    name: 'test',
    component: test,
  },
  {
    path: '/test2',
    name: 'test2',
    component: test2,
  },
  {
    path: '/test3',
    name: 'test3',
    component: Test3,
  },
  
  {
    path: '/test4',
    name: 'test4',
    component: test4,
  },
  {
    path: '/test5',
    name: 'test5',
    component: test5,
  },

  // liens utiles
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },

  {
    path: '/aboutus',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },

  // food
  {
    path: '/detail/:id',
    name:'detail',
    component: detail
  },

    // 404
  {
    path : '/:pathMatch(.*)*',
    name : 'NotFound',
    component: NotFound
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
