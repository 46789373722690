import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'animate.css';
import Vuex from 'vuex'
import localforage from 'localforage'

localforage.config({
    driver:[
        localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
        localforage.WEBSQL,
    ],
    name: 'myApp',
    storeName:'auth',
    version: 1
})

axios.defaults.withCredentials= true
// axios.defaults.baseURL= "http://127.0.0.1:8000/api/"
axios.defaults.baseURL= "https://temsis-food.herokuapp.com/api"

createApp(App).use(router, store, axios, VueAxios, AOS, Vuex, localforage).mount('#app')
