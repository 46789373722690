<template>
  <div>
    <navbar></navbar>
    <header>
      <div>
        <h2>A propos de nous</h2>
        <li><router-link to="/">Accueil </router-link>/A propos de nous</li>
      </div>
    </header>
    <section class="about">
      <div class="first">
        <div class="img">
          <img src="../../assets/about-1.jpg" alt="">
        </div>
        <div class="first-text">
          <h3>Merite Food</h3>
          <h4>A propos du restaurant</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint commodi quidem animi modi eveniet est quas dicta nihil soluta reprehenderit dolore, ullam, neque harum recusandae perspiciatis quam nemo minima? At.
          </p>
        </div>
      </div>
      <div class="history">
        <h3>Découvrez</h3>
        <h4>Notre histoire</h4>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium,
          hic. Impedit veniam neque quas officia dolorem repellendus sit harum
          culpa officiis. Eius iure sed rem impedit ipsam aliquid. Suscipit
          recusandae totam exercitationem facilis modi assumenda iste, id
          ratione sunt ipsum in, quis ullam cupiditate, dolores nam aut quo
          reprehenderit hic.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium,
          hic. Impedit veniam neque quas officia dolorem repellendus sit harum
          culpa officiis. Eius iure sed rem impedit ipsam aliquid. Suscipit
          recusandae totam exercitationem facilis modi assumenda iste, id
          ratione sunt ipsum in, quis ullam cupiditate, dolores nam aut quo
          reprehenderit hic.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium,
          hic. Impedit veniam neque quas officia dolorem repellendus sit harum
          culpa officiis. Eius iure sed rem impedit ipsam aliquid. Suscipit
          recusandae totam exercitationem facilis modi assumenda iste, id
          ratione sunt ipsum in, quis ullam cupiditate, dolores nam aut quo
          reprehenderit hic.
        </p>
      </div>
    </section>
    <feedback></feedback>
    <reservation></reservation>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "../../components/MyContact.vue";
import Navbar from "../../components/MyNavbar.vue";
import Feedback from "../../components/MyFeedBack.vue";
import Reservation from "../../components/MyReservation.vue";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  created() {
    AOS.init();
  },
  components: {
    Contact,
    Navbar,
    Feedback,
    Reservation,
  },
};
</script>

<style scoped>
header {
  background-image: url("~@/assets/blog-7.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;

  align-items: center;
}

header div {
  color: var(--color1);
  font-size: 20px;
  font-family: var(--first-police);
}

header div h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--first-police);
  text-align: center;
}

.about {
  height: auto;
  padding: 0px;
  padding-top: 70px;
}

.first {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.img {
  width: 40%;
}

.first h3 {
  font-family: var(--cursive-police);
  color: var(--color2);
}

.first h4 {
  font-family: var(--first-police);
  text-transform: uppercase;
  font-size: 50px;
}

.first-text {
  width: 30%;
}

.first-text p{
 font-family: var(--second-police);
}

.history {
  margin-top: 30px;
  padding: var(--pad);
  background-color: var(--color4);
}

.history h3 {
  font-family: var(--cursive-police);
  color: var(--color2);
  text-align: center;
}

.history h4 {
  font-family: var(--first-police);
  text-transform: uppercase;
  text-align: center;
  font-size: 50px;
}

.history p {
  line-height: 1.5;
  font-size: 15px;
  width: 50%;
  margin: 10px auto;
  font-family: var(--second-police);
}

/* responsive */
@media screen and (max-width: 1000px) {
  .first {
    flex-direction: column;
  }

  .first-text {
    width: 80%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .history p {
    width: 80%;
  }

  .first h4 {
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .first{
    width: 80%;
    margin: auto;
    text-align: center;
  }

  .img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .img img {
    width: 300px;
  }

  .first-text {
    margin: 0px;
  }
}
</style>