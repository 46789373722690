<template>
  <div>
    <nav>
      <div class="logo">
        <img src="../../assets/logo.png" alt="" />
      </div>

      <div class="menu">
        <a href="#"> Accueil</a>
        <a href="#"> Politique</a>
        <a href="#three"> Sports</a>
        <a href="#"> Divertissement</a>
        <a href="#"> Culture</a>
        <a href="#"> Se connecter</a>
      </div>
    </nav>
    <div class="text">
      <h1>Premier</h1>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maiores,
        voluptatem laboriosam. Recusandae, sunt officia iste odio ipsam alias,
        dolor, corrupti aspernatur nobis veritatis sed laudantium eligendi
        tempora! Quidem fugiat quas similique tempora deserunt, nobis, molestiae
        illum autem, magnam iusto maiores rem aperiam natus explicabo placeat
        beatae voluptatibus architecto possimus. Optio laborum incidunt nobis,
        fugiat accusantium aliquid praesentium repellendus minima velit sed quas
        saepe deleniti quo! Dolore eaque distinctio cupiditate, fugit ea animi,
        consectetur corporis nisi adipisci perspiciatis eos? Reprehenderit sed
        repellat nemo, expedita animi maxime, explicabo ad reiciendis, delectus
        aliquid dolor. Facilis, pariatur corrupti odit eius error facere impedit
        fugit!
      </p>
    </div>
    <div class="text">
      <h2>Deuxieme</h2>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maiores,
        voluptatem laboriosam. Recusandae, sunt officia iste odio ipsam alias,
        dolor, corrupti aspernatur nobis veritatis sed laudantium eligendi
        tempora! Quidem fugiat quas similique tempora deserunt, nobis, molestiae
        illum autem, magnam iusto maiores rem aperiam natus explicabo placeat
        beatae voluptatibus architecto possimus. Optio laborum incidunt nobis,
        fugiat accusantium aliquid praesentium repellendus minima velit sed quas
        saepe deleniti quo! Dolore eaque distinctio cupiditate, fugit ea animi,
        consectetur corporis nisi adipisci perspiciatis eos? Reprehenderit sed
        repellat nemo, expedita animi maxime, explicabo ad reiciendis, delectus
        aliquid dolor. Facilis, pariatur corrupti odit eius error facere impedit
        fugit!
      </p>
    </div>
    <div id="three" class="text">
      <h3>Troisieme</h3>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maiores,
        voluptatem laboriosam. Recusandae, sunt officia iste odio ipsam alias,
        dolor, corrupti aspernatur nobis veritatis sed laudantium eligendi
        tempora! Quidem fugiat quas similique tempora deserunt, nobis, molestiae
        illum autem, magnam iusto maiores rem aperiam natus explicabo placeat
        beatae voluptatibus architecto possimus. Optio laborum incidunt nobis,
        fugiat accusantium aliquid praesentium repellendus minima velit sed quas
        saepe deleniti quo! Dolore eaque distinctio cupiditate, fugit ea animi,
        consectetur corporis nisi adipisci perspiciatis eos? Reprehenderit sed
        repellat nemo, expedita animi maxime, explicabo ad reiciendis, delectus
        aliquid dolor. Facilis, pariatur corrupti odit eius error facere impedit
        fugit!
      </p>
    </div>
    <div class="text">
      <h4>Quatrieme</h4>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maiores,
        voluptatem laboriosam. Recusandae, sunt officia iste odio ipsam alias,
        dolor, corrupti aspernatur nobis veritatis sed laudantium eligendi
        tempora! Quidem fugiat quas similique tempora deserunt, nobis, molestiae
        illum autem, magnam iusto maiores rem aperiam natus explicabo placeat
        beatae voluptatibus architecto possimus. Optio laborum incidunt nobis,
        fugiat accusantium aliquid praesentium repellendus minima velit sed quas
        saepe deleniti quo! Dolore eaque distinctio cupiditate, fugit ea animi,
        consectetur corporis nisi adipisci perspiciatis eos? Reprehenderit sed
        repellat nemo, expedita animi maxime, explicabo ad reiciendis, delectus
        aliquid dolor. Facilis, pariatur corrupti odit eius error facere impedit
        fugit!
      </p>
    </div>
    <div class="text">
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maiores,
        voluptatem laboriosam. Recusandae, sunt officia iste odio ipsam alias,
        dolor, corrupti aspernatur nobis veritatis sed laudantium eligendi
        tempora! Quidem fugiat quas similique tempora deserunt, nobis, molestiae
        illum autem, magnam iusto maiores rem aperiam natus explicabo placeat
        beatae voluptatibus architecto possimus. Optio laborum incidunt nobis,
        fugiat accusantium aliquid praesentium repellendus minima velit sed quas
        saepe deleniti quo! Dolore eaque distinctio cupiditate, fugit ea animi,
        consectetur corporis nisi adipisci perspiciatis eos? Reprehenderit sed
        repellat nemo, expedita animi maxime, explicabo ad reiciendis, delectus
        aliquid dolor. Facilis, pariatur corrupti odit eius error facere impedit
        fugit!
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--bg1);
  padding: 10px 0px 10px 0px;
}

.logo img {
  width: 50px;
}

.menu a {
  margin-left: 10px;
  text-transform: uppercase;
}

.text {
  line-height: 2;
  width: 50%;
  margin: 30px auto;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
</style>