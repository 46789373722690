<template>
  <div>
    <!-- desktop version -->
    <section class="chef desktop" id="chefs">
      <p>rencontrer nos experts culinaires</p>
      <h2>Nos meilleurs chefs</h2>

      <swiper
        :slides-per-view="3"
        :space-between="5"
        :pagination="{ clickable: false }"
        autoplay
      >
        <swiper-slide v-for="(chef, i) in chefs" :key="i">
          <div class="chef-sub">
            <div
              class="row"
              data-aos="flip-left"
              data-aos-duration="500"
              data-aos-delay="400"
            >
              <!-- <img
                class="chef-img"
                :src="`http://127.0.0.1:8000${chef.image}`"
                alt="chef"
              /> -->
              <img class="chef-img" src="@/assets/chef/chef-1.jpg" alt="chef" />

              <div class="chef-info">
                <p class="chef-name">{{ chef.name }}</p>
                <p class="chef-role">{{ chef.role }}</p>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </section>

    <!-- mobile version -->
    <section class="chef mobile" id="chefs">
      <p>rencontrer nos experts culinaires</p>
      <h2>Nos meilleurs chefs</h2>

      <swiper
        :slides-per-view="1"
        :space-between="5"
        :pagination="{ clickable: false }"
        autoplay
      >
        <swiper-slide v-for="(chef, i) in chefs" :key="i">
          <div class="chef-sub">
            <div
              class="row"
              data-aos="flip-left"
              data-aos-duration="500"
              data-aos-delay="400"
            >
              <!-- <img
                class="chef-img"
                :src="`http://127.0.0.1:8000${chef.image}`"
                alt="chef"
              /> -->
              <img class="chef-img"  src="@/assets/chef/chef-1.jpg" alt="chef" />

              <div class="chef-info">
                <p class="chef-name">{{ chef.name }}</p>
                <p class="chef-role">{{ chef.role }}</p>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </section>
  </div>
</template>

<script>
// aos
import AOS from "aos";
import "aos/dist/aos.css";

// axios
import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

// Import Swiper styles
import "swiper/swiper.scss";

export default {
  created() {
    AOS.init();
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      chefs: [],
    };
  },
  mounted() {
    axios
      .get("chef/all")
      .then((response) => {
        this.chefs = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.desktop {
  display: block;
}

.mobile {
  display: none;
}

.chef {
  background-color: var(--bg2);
  position: relative;
  overflow: hidden;
  height: auto;
  padding: var(--pad);
}

.chef::before {
  content: "";
  background-image: url("~@/assets/chef/chef-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chef h2 {
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  color: var(--color1);
  margin-bottom: 20px;
}

.chef p:first-of-type {
  text-align: center;
  color: var(--color2);
  font-family: var(--cursive-police);
  font-size: 23px;
  text-transform: capitalize;
}

.chef-sub {
  display: flex;
  justify-content: space-around;
}

.row {
  /* width: calc(100% / 3); */
  padding: 0 15px;
  position: relative;
}

.chef-img {
  width: 300px;
  border-radius: 20px;
}

.chef-info {
  background-color: #191f3a;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  position: absolute;
  left: 60px;
  bottom: 40px;
  transform: translateY(50%);
  transition: all 0.3s ease;
  pointer-events: none;
  width: 70%;
}

.chef-name {
  text-align: center;
  color: var(--color2);
  font-family: var(--cursive-police);
  font-size: 23px;
}

.row:hover .chef-info {
  transform: translateY(-15px);
  background-color: var(--color2);
}

.row:hover .chef-name {
  color: var(--color1);
}

.chef-info p:last-child {
  color: var(--color1);
  font-family: var(--cursive-police);
  font-size: 20px;
}

/* responsive */
@media screen and (max-width: 800px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .chef-info {
    transform: none;
    width: 90%;
    margin: auto;
    position: relative;
    bottom: 0;
    left: 0;
    padding: 0;
  }

  .chef-img {
    margin: auto;
  }

  .chef-img {
    margin: auto;
  }
  .chef-info {
    width: 55%;
  }
  .row:hover .chef-info {
    transform: translateY(-55px);
    background-color: #eaa023;
  }
}
</style>