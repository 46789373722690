<template>
  <div>
    <section class="about">
      <div
        class="details"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <h2>Merite Food</h2>
        <h3>A propos</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod nemo
          laboriosam, eligendi voluptatum exercitationem veniam nostrum sint,
          enim incidunt a modi placeat labore est voluptate dignissimos
          repellendus autem ullam nesciunt.
        </p>
        <br />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod nemo
          laboriosam, eligendi voluptatum exercitationem veniam nostrum sint,
          enim incidunt a modi placeat labore est voluptate dignissimos
          repellendus autem ullam nesciunt.
        </p>
        <br />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod nemo
          laboriosam, eligendi voluptatum exercitationem veniam nostrum sint,
          enim incidunt a modi placeat labore est voluptate dignissimos
          repellendus autem ullam nesciunt.
        </p>
        <br />

        <router-link to="/aboutus"
          ><button class="btn">Voir plus</button>
        </router-link>
      </div>
      <div
        class="img"
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-delay="400"
      ></div>
    </section>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  created() {
    AOS.init();
  },
  name: "MyAbout",
};
</script>

<style scoped>
.about {
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.details {
  width: 40%;
  margin: auto;
}

.details p {
  font-family: var(--second-police);
}

.details h2 {
  font-family: var(--cursive-police);
  color: var(--color2);
}

.details h3 {
  font-family: var(--first-police);
  text-transform: uppercase;

  font-size: 50px;
}

.img {
  width: 40%;
  background-image: url("~@/assets/about-pizzon.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 50vh;
}

.btn {
  padding: 10px;
  border-radius: 5px;
  border: 0px;
  background-color: var(--color2);
  color: var(--color1);
  text-transform: uppercase;
  font-family: var(--first-police);
  outline: none;
  width: 200px;
  margin: 0px auto;
  display: block;
}

button:hover {
  color: var(--color2);
  background-color: var(--color1);
  border: 1px solid var(--color2);
}

button:hover a {
  color: var(--color2);
}

/* responsive */
@media screen and (max-width: 900px) {
  .about {
    flex-direction: column;
  }

  .details h2,
  .details h3 {
    text-align: center;
  }

  .details {
    width: 60%;
    margin-bottom: 10px;
  }

  .img {
    margin-top: 10px;
  }
}

@media screen and (max-width: 450px) {
  .details {
    width: 80%;
    margin: auto;
  }

  .img {
    height: 30vh;
  }
}
</style>