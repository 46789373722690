<template>
  <div>
    <h1>Test</h1>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae velit nam
      quis error ullam odio tenetur? Veritatis blanditiis recusandae asperiores
      ratione animi cupiditate molestiae pariatur delectus quia facilis impedit
      vero odio quaerat optio quidem officiis, incidunt non quo totam sint!
      Saepe in autem laboriosam repudiandae iure eius officiis nihil facere
      architecto corrupti rerum esse et obcaecati mollitia assumenda voluptate,
      cum nobis voluptas qui incidunt amet odit. Nobis iure dolorem alias ullam
      facere laborum cupiditate, quidem in aliquid dolores autem, atque error
      quae et magnam ut consectetur laudantium ad, natus temporibus sit
      aspernatur ab nostrum earum. Sapiente odit labore magnam earum?
    </p>
    <div class="actions">
      <div>
        <button @click="increment">Incrémenter</button>
        <button @click="decrement">Décrémenter</button>
      </div>
      <p>{{ test }}</p>
      <!-- GRADIENT SPINNER -->
      <div v-if="loading" class="spinner-box">
        <div class="circle-border">
          <div class="circle-core"></div>
        </div>
      </div>
    </div>
    <div class="img">
      <img src="../../assets/chef/chef-1.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      test: 0,
      loading: false,
    };
  },

  methods: {
    increment() {
      let number = Number(this.test++);
      if (number === 2) {
        console.log("Votre nombre est positif");
        this.loading = true;
      }
      console.log(number);
    },
    decrement() {
      let number = Number(this.test--);
      if (number === -2) {
        console.log("Votre nombre est négatif");
        this.loading = false;
      }
      console.log(number);
    },
  },
};
</script>

<style scoped>
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin: 0px auto;
}

.circle-border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63, 249, 220);
  background: linear-gradient(
    0deg,
    rgba(63, 249, 220, 0.1) 33%,
    rgba(63, 249, 220, 1) 100%
  );
  animation: spin 0.8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
  border-radius: 50%;
}

h1 {
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  margin: 10px 0px 0px 0px;
}

p {
  font-size: 18px;
  line-height: 2;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  width: 50%;
  margin: 0px auto;
  text-align: center;
}

/* p:first-of-type{
  background-image: url('../../assets/chef/chef-1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
} */

.img,
.actions {
  width: 50%;
  margin: 0px auto;
}

.actions {
  margin-bottom: 10px;
}

.actions div {
  display: flex;
  justify-content: space-around;
}

button {
  padding: 10px;
  border: 0px;
  border-radius: 10px;
  color: white;
  background-color: var(--bg1);
  cursor: pointer;
}

.img img {
  width: 600px;
  border-radius: 10px;
}
</style>