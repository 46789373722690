<template>
  <div>
    <navbar></navbar>
    <section class="page_404">
      <div class="bg"></div>

      <div class="contant_box_404">
        <h3>La page sélectionné n'existe pas</h3>

        <p>Cliquer sur le bouton pour retourner a l'accueil</p>

        <router-link to="/" class="btn">Accueil</router-link>
      </div>
    </section>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "../../components/MyContact.vue";
import Navbar from "../../components/MyNavbar.vue";

export default {
  components: {
    Contact,
    Navbar,
  },
};
</script>

<style scoped>
.page_404 {
  background: var(--color1);
  padding: 80px 0px;
}

.page_404 img {
  width: 100%;
}

.bg {
  background-image: url("~@/assets/404/2.gif");
  background-repeat: no-repeat;
  background-size: contain;
  height: 400px;
  background-position: center;
}

.bg h2,
.bg h3 {
  font-size: 80px;
  font-family: var(--first-police);
}

.contant_box_404 {
  margin-top: -50px;
  text-align: center;
  line-height: 1.6;
  font-family: var(--first-police);
  font-size: 20px;
  text-transform: capitalize;
}
</style>