<template>
  <div>
    <Navbar></Navbar>
    <header>
      <div>
        <h2>Menu</h2>
        <li><router-link to="/">Accueil </router-link> / Menu</li>
      </div>
    </header>
    <section class="menu">
      <div class="icones">
        <i class="fas fa-pizza-slice fa-2x" @click="chooseMenu(1)"> Pizza</i>
        <i class="fas fa-hamburger fa-2x" @click="chooseMenu(2)"> Burger</i>
        <i class="fas fa-cocktail fa-2x" @click="chooseMenu(3)">Cocktail</i>
      </div>

      <section
        v-if="pizza"
        class="food"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <h2 class="food-menu">Votre Menu Pizza</h2>
        <p>
          Laissez vous emmerveillez pas nos pizzas au rapport gout-prix
          inegalable
          <br />
          et venez faire un voyage culinaire avec nous
        </p>

        <div class="first">
          <div class="pizza" v-for="pizza in pizzas" :key="pizza.id">
            <!-- <img :src="`http://127.0.0.1:8000${pizza.image}`" alt="plat" /> -->
            <img src="@/assets/pizza/menu-1.png" alt="plat" />

            <p class="pizza-name">{{ pizza.name }}</p>

            <p class="pizza-des">{{ pizza.description }}</p>

            <p class="prix">{{ pizza.price }} FCFA</p>

            <router-link :to="{ name: 'detail', params: { id: pizza.id } }">
              <button class="btn">Commander maintenant</button></router-link
            >
          </div>
        </div>
      </section>

      <section
        v-if="burger"
        class="food"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <h2 class="food-menu">Votre Menu Burger</h2>
        <p>
          Laissez vous émerveillez pas nos burgers au rapport gout-prix
          inégalable
          <br />
          et venez faire un voyage culinaire avec nous
        </p>

        <div class="first">
          <div class="pizza" v-for="(burger, i) in burgers" :key="i">
            <!-- <img :src="`http://127.0.0.1:8000${burger.image}`" alt="plat" /> -->
            <img src="@/assets/burger/burger1.png" alt="plat" />

            <p class="pizza-name">{{ burger.name }}</p>

            <p class="pizza-des">{{ burger.description }}</p>

            <p class="prix">{{ burger.price }} FCFA</p>

            <router-link :to="{ name: 'detail', params: { id: burger.id } }">
              <button class="btn">Commander maintenant</button></router-link
            >
          </div>
        </div>
      </section>

      <section
        v-if="cocktail"
        class="food"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <h2 class="food-menu">Nos cocktails</h2>
        <p>
          Prenez plaisir plaisir a déguster nos cocktails de divers regions du
          monde
          <br />
          pour sublimer nos repas
        </p>

        <div class="first">
          <div class="pizza" v-for="(cocktail, i) in cocktails" :key="i">
            <!-- <img :src="`http://127.0.0.1:8000${cocktail.image}`" alt="plat" /> -->
            <img src="@/assets/juice/juice1.png" alt="plat" />

            <p class="pizza-name">{{ cocktail.name }}</p>

            <p class="pizza-des">{{ cocktail.description }}</p>

            <p class="prix">{{ cocktail.price }} FCFA</p>

            <router-link :to="{ name: 'detail', params: { id: cocktail.id } }">
              <button class="btn">Commander maintenant</button></router-link
            >
          </div>
        </div>
      </section>
    </section>
    <chef></chef>
    <reservation></reservation>
    <contact></contact>
  </div>
</template>

<script>
// axios
import axios from "axios";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

// components
import Navbar from "../../components/MyNavbar.vue";
import Contact from "../../components/MyContact.vue";
import Chef from "../../components/MyChef.vue";
import Reservation from "../../components/MyReservation.vue";
export default {
  created() {
    AOS.init();
  },

  components: {
    Navbar,
    Contact,
    Chef,
    Reservation,
  },

  mounted() {
    axios
      .get("food/pizza")
      .then((response) => {
        this.pizzas = response.data;
      })
      .catch((error) => {
        this.info = error.message;
      });
    axios
      .get("food/burger")
      .then((response) => {
        this.burgers = response.data;
      })
      .catch((error) => {
        this.info = error.message;
      }),
      axios
        .get("food/cocktail")
        .then((response) => {
          this.cocktails = response.data;
        })
        .catch((error) => {
          this.info = error.message;
        });
  },
  data() {
    return {
      pizzas: [],
      burgers: [],
      cocktails: [],
      pizza: true,
      burger: false,
      cocktail: false,
      panier: [],
      quantity: "",
      info: "",
    };
  },
  methods: {
    chooseMenu(a) {
      this.pizza = a === 1 ? true : false;
      this.burger = a === 2 ? true : false;
      this.cocktail = a === 3 ? true : false;
    },
  },
};
</script>

<style scoped>
header {
  background-image: url("~@/assets/menu-banner-1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

header div {
  color: var(--color1);
  font-size: 20px;
  font-family: var(--first-police);
}

header div h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--first-police);
  text-align: center;
}

.link {
  color: var(--title-color);
  font-size: 18px;
}

header div li a {
  color: var(--bg1-color);
  font-size: 20px;
  font-family: var(--first-police);
  padding: 10px;
}

/* food */
.menu {
  position: relative;
  background-color: var(--color1);
  overflow: hidden;
  height: auto;
  padding: var(--pad);
}

.food-menu {
  font-size: 25px;
  text-align: center;
  font-family: var(--cursive-police);
  color: var(--color2);
}

.food p:first-child {
  font-size: 25px;
  font-family: var(--first-police);
}

.food p:last-of-type {
  text-align: center;
  color: var(--color3);
  font-size: 20px;
  font-family: var(--first-police);
}

.first {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  cursor: pointer;
  line-height: 1.5;
}

.pizza {
  text-align: center;
  width: 30%;
  margin: 10px 0px;
  padding: 15px 0;
  font-size: 18px;
  color: white;
  border-radius: 10%;
  border: 4px solid var(--color3);
}

.pizza-name {
  text-transform: uppercase;
  font-family: var(--second-police);
  font-size: 20px;
  color: var(--color3);
}

.pizza-des {
  text-transform: capitalize;
  font-size: 15px;
  color: var(--color2);
  font-family: var(--second-police);
}

.quantity {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.quantity::placeholder {
  color: #999;
  opacity: 1;
}

.prix {
  color: var(--color2);
}

.pizza img {
  width: 100px;
  transition: transform 0.5s ease;
}

.pizza:hover img {
  transform: translateY(-10px);
}

.icones {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: auto;
  flex-wrap: wrap;
  width: 60%;
  border-radius: 5px;
  padding: 5px;
}

.icones i {
  padding: 10px;
  cursor: pointer;
  color: var(--color3);
  border: 1px solid var(--color2);
}

.icones i:focus {
  background: var(--color2);
  border-radius: 5px;
}

/* responsive */
@media screen and (max-width: 1150px) {
  .pizza {
    width: 40%;
  }
}

@media screen and (max-width: 750px) {
  .pizza {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .icones i {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 550px) {
  .food p:last-of-type {
    width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 430px) {
  .icones {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
  }

  .icones i {
    margin-left: 10px;
    margin-top: 10px;
  }
}
</style>