<template>
  <div>
    <Navbar></Navbar>
    <div class="body">
      <div class="content">
        <form @submit.prevent="setConnexion">
          <h2>Se connecter</h2>
          <div class="field">
            <label for="inputEmail">{{ response }}</label>
            <input
              type="email"
              id="inputEmail"
              v-model="connexion.email"
              placeholder="Email"
              required
            />

            <label for="inputEmail"></label>
          </div>

          <div class="field">
            <input
              :type="passwordFieldType"
              v-model="connexion.password"
              id="inputPassword"
              placeholder="Mot de passe"
              required
            />

            <i class="fas fa-eye" v-if="icon1" @click="showPassword"></i>
            <i class="fas fa-eye-slash" v-if="icon2" @click="showPassword"></i>
          </div>

          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="connexion.remember" /> Se souvenir
              de moi
            </label>
          </div>

          <div class="forgot-pass">
            <router-link to="/reset">Mot de passe oublié?</router-link>
          </div>

          <button type="submit">Se connecter</button>

          <div class="sign-up">
            <p>
              Pas inscrit?<router-link to="/register">
                S'inscrire maintenant</router-link
              >
            </p>
          </div>
        </form>
      </div>
    </div>
    <contact></contact>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store/index";
import Navbar from "../../components/MyNavbar.vue";
import Contact from "../../components/MyContact.vue";
import localforage from "localforage";
// js cookie
import Cookies from "js-cookie";

export default {
  components: {
    Navbar,
    Contact,
  },
  data() {
    return {
      connexion: {
        email: null,
        password: null,
      },
      icon1: true,
      icon2: false,
      remember: false,
      response: "",
      password: "",
      passwordFieldType: "password",
    };
  },
  methods: {
    showPassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.icon1 = this.icon1 === true ? false : true;
      this.icon2 = this.icon2 === false ? true : false;
    },

    async setConnexion() {
      axios
        .post("auth/signin", { ...this.connexion })
        .then((response) => {
          if (
            response.data.status == "success" &&
            response.data.data.role_id == 1
          ) {
            Cookies.set("token", response.data.token);

            localforage
              .setItem("user", response.data)
              .then((userData) => {
                store.commit("setCurrentUser", userData);
                this.$router.push({ name: "Admin" });
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (
            response.data.status == "success" &&
            response.data.data.role_id == 2
          ) {
            Cookies.set("token", response.data.token);

            localforage
              .setItem("user", response.data)
              .then((userData) => {
                store.commit("setCurrentUser", userData);
                this.$router.push({ name: "userProfile" });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((error) => {
          return (this.response = error.response.data.message);
        });
    },
  },
};
</script>


<style scoped>
.body {
  display: grid;
  place-items: center;
  background: #dde1e7;
  text-align: center;
  height: 700px;
}

.content {
  width: 330px;
  padding: 40px 30px;
  background: #dde1e7;
  border-radius: 10px;
  box-shadow: -3px -3px 7px #ffffff73, 2px 2px 5px rgba(94, 104, 121, 0.288);
  /* animation: backInDown 0.5s;
  transition: animation 0.5s; */
}

.content .text {
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 35px;
  color: var(--color3);
}

form h2 {
  font-weight: 600;
  text-align: center;
  color: var(--color3);
  font-size: 25px;
}

.field {
  height: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.field i {
  position: absolute;
  right: 55px;
  cursor: pointer;
  top: 25px;
}

.field:nth-child(2) {
  margin-top: 20px;
}

.field input {
  padding: 20px;
  margin: 10px auto;
  border-radius: 5px;
  border: 0;
  outline: none;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}

.field input:focus {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #ffffff73;
}

.checkbox {
  margin-top: 10px;
  font-weight: 600;
  color: var(--color3);
  font-family: var(--second-police);
}

.field label {
  position: absolute;
  transform: translateY(-50%);
  left: 35px;
  color: var(--title-color);
}

.field input:valid ~ label {
  opacity: 0;
}

.forgot-pass {
  text-align: center;
  margin: 10px 0 10px 5px;
  font-family: var(--second-police);
}

.forgot-pass a {
  font-size: 16px;
  color: #3498db;
  text-decoration: none;
}

.forgot-pass:hover a {
  text-decoration: underline;
}

button {
  margin: 15px 0;
  width: 70%;
  height: 50px;
  font-size: 18px;
  line-height: 50px;
  font-weight: 600;
  background: #dde1e7;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--color3);
  box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;
}

button:focus {
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}

.sign-up {
  margin: 10px 0;
  color: var(--color3);
  font-size: 16px;
  position: relative;
  font-family: var(--second-police);
}

.sign-up a {
  color: #3498db;
  text-decoration: none;
}

.sign-up a:hover {
  text-decoration: underline;
}
</style>