<template>
  <div>
    <Navbar></Navbar>
    <header>
      <div>
        <h2>panier</h2>
        <li><router-link to="/"> Accueil </router-link>/ Panier</li>
      </div>
    </header>
    <section class="order">
      <div class="table-responsive">
        <table class="table border">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Prix</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(panier, i) in paniers" :key="i">
              <td>{{ panier.name }}</td>

              <td>{{ panier.price }}</td>

              <td class="action">
                <i class="fas fa-trash supp" @click="removePanier(i)">
                  supprimer
                </i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="boutons">
        <router-link to="/menu">
          <button class="btn">Continuez a commander</button></router-link
        >
      </div>

      <div class="cart" v-if="(cart = items)">
        <h2>Total panier</h2>
        <div>
          <p>Nombre d'elements</p>
          <p>{{ items }}</p>
        </div>
        <div>
          <p>Price total</p>
          <p>{{ priceTotal }}</p>
        </div>

        <router-link to="/checkout">
          <button class="btn">Procéder au paiement</button></router-link
        >
      </div>
    </section>
    <contact></contact>
  </div>
</template>

<script>
import localforage from "localforage";

import Contact from "../../components/MyContact.vue";
import Navbar from "../../components/MyNavbar.vue";
import store from "../../store/index";
export default {
  async mounted() {
    if (
      Object.keys(store.state.panier).length === 0 &&
      store.state.panier.constructor === Array
    ) {
      localforage.getItem("food").then((food) => {
        store.dispatch("ajoutPanier", food);
      });
    }
  },
  components: {
    Contact,
    Navbar,
  },
  data() {
    return {
      cart: null,
    };
  },
  methods: {
    removePanier(a) {
      localforage.getItem("food").then((foodAdded) => {
        foodAdded.splice(a, 1);
        store.commit("removePanier", a);
        localforage.setItem("food", foodAdded);
      });
    },
  },
  computed: {
    priceTotal() {
      return store.getters.priceTotal;
    },
    items() {
      let item = store.state.panier ? store.state.panier.length : 0;
      return item;
    },

    Panier() {
      return store.getters.getCart;
    },

    paniers() {
      return store.state.panier;
    },
  },
};
</script>

<style scoped>
header {
  background-image: url("~@/assets/blog-8.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action {
  cursor: pointer;
}

header div {
  color: var(--color1);
  font-size: 20px;
  font-family: var(--first-police);
}

header div h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--first-police);
  text-align: center;
}

header div li {
  text-align: center;
}

.order {
  height: auto;
}

.table-responsive {
  width: 80%;
  margin: auto;
  overflow-x: auto;
}
/* table */
table {
  border-collapse: collapse;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.border {
  border: 1px solid #dee2e6 !important;
}

table.border {
  border-color: #e5e5e5 !important;
}

.align-left {
  text-align: left !important;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid #dee2e6;
  font-family: var(--second-police);
  font-size: 18px;
}

.table td {
  font-family: var(--second-police);
  font-size: 15px;
  text-align: center;
}

thead th {
  border-bottom: 2px solid #dee2e6 !important;
}

.boutons {
  display: flex;
  margin-top: 10px;
  width: 80%;
  margin: auto;
  text-align: center;
}

/* cart */
.cart {
  width: 40%;
  margin: 20px;
  margin-left: 130px;
  border: 1px solid var(--border-color);
  padding: 20px;
}

.cart h2 {
  text-align: center;
  margin-bottom: 10px;
}

.cart div {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  padding: 20px 5px;
  margin-bottom: 10px;
}

/* responsive */
@media screen and (max-width: 650px) {
  .cart {
    width: 80%;
    margin: 20px auto;
  }
}
</style>