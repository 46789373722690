<template>
  <div>
    <section
      class="feedback"
      data-aos="fade-down"
      data-aos-duration="1000"
      data-aos-delay="400"
    >
      <p class="clients-say">Que pensent nos clients</p>
      <h2>Avis des clients</h2>

      <swiper
        :slides-per-view="1"
        :space-between="5"
        :pagination="{ clickable: true }"
        autoplay
      >
        <swiper-slide v-for="(feedback, i) in feedbacks" :key="i">
          <div class="feedback-sub">
            <img :src="require(`@/assets/feedback/${feedback.img}`)" />
            <p class="feed-avis">{{ feedback.avis }}</p>
            <div class="author">
              <p>{{ feedback.name }}</p>
              <p>{{ feedback.job }}</p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

// Import Swiper styles
import "swiper/swiper.scss";
export default {
  components: {
    Swiper,
    SwiperSlide,
    // Loader,
  },
  data() {
    return {
      feedbacks: [
        {
          img: "fb1.jpg",
          avis: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur velit doloremque accusamus ex, eligendi iste sed excepturi veniam a? Optio molestias consequuntur fugiat architecto fuga veniam dolorem impedit quia aut numquam asperiores eligendi nesciunt, recusandae harum modi maxime veritatis. Dolores tempora, in vel sunt incidunt culpa quae beatae sapiente. Et unde saepe alias reprehenderit. Nihil, fugiat. Porro ipsa ducimus corrupti!",
          name: "Jacquie",
          job: "Designeuse",
        },
        {
          img: "fb2.jpg",
          avis: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur velit doloremque accusamus ex, eligendi iste sed excepturi veniam a? Optio molestias consequuntur fugiat architecto fuga veniam dolorem impedit quia aut numquam asperiores eligendi nesciunt, recusandae harum modi maxime veritatis. Dolores tempora, in vel sunt incidunt culpa quae beatae sapiente. Et unde saepe alias reprehenderit. Nihil, fugiat. Porro ipsa ducimus corrupti!",
          name: "Emilie",
          job: "Enseignante",
        },
        {
          img: "fb3.jpg",
          avis: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur velit doloremque accusamus ex, eligendi iste sed excepturi veniam a? Optio molestias consequuntur fugiat architecto fuga veniam dolorem impedit quia aut numquam asperiores eligendi nesciunt, recusandae harum modi maxime veritatis. Dolores tempora, in vel sunt incidunt culpa quae beatae sapiente. Et unde saepe alias reprehenderit. Nihil, fugiat. Porro ipsa ducimus corrupti!",
          name: "Marien",
          job: "Etudiant",
        },
      ],
    };
  },
};
</script>

<style scoped>
.feedback {
  height: auto;
  background-color: var(--bg3);
  padding: var(--pad);
}

.feedback,
p:first-child h2 {
  text-align: center;
}

.clients-say {
  color: var(--color2);
  font-size: 23px;
  font-family: var(--cursive-police);
  padding-right: 10px;
}

.feedback h2 {
  color: var(--color1);
  text-transform: uppercase;
}

.feedback-sub {
  text-align: center;
  width: 70%;
  margin: auto;
  margin-top: 40px;
}

.feedback-sub img {
  width: 100px;
  border-radius: 50%;
  padding: none;
  display: block;
  border: 6px solid var(--color2);
}

.feed-avis {
  border: 2px solid var(--color1);
  padding: 30px 0;
  color: gray;
  background-color: var(--color1);
  font-size: 18px;
  text-align: center;
  font-family: var(--second-police);
  margin-top: 10px;
  border-radius: 20px;
}

.author {
  display: flex;
}

.author p:first-child {
  color: var(--color2);
  font-size: 23px;
  font-family: var(--cursive-police);
  padding-right: 10px;
}

.author p:last-child {
  color: var(--color1);
  text-transform: capitalize;
  font-family: var(--cursive-police);
  font-size: 20px;
}

@media screen and (max-width: 750px) {
  .feedback-sub img {
    width: 100px;
    margin: auto;
  }

  .author {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 550px) {
  .feedback-sub {
    width: 90%;
    margin: auto;
  }
}
</style>