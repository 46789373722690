<template>
  <div>
    <div class="bloc-modale" v-if="revele">
    <div class="couche" @:click="toggleModale"></div>

    <div class="modale">
      <div v-on:click="toggleModale" class="btn-modale btn">X</div>
      <h2>Modification des elements du menu</h2>

      <form @submit.prevent="updateFood">
        <input type="hidden" v-model="update.id" />

        <input type="text" v-model="update.name" />

        <input type="text" v-model="update.price" />

        <input type="text" v-model="update.description" />

        <!-- <input type="text" v-model="update.image" /> -->
        <input
          type="file"
          @change="getImage"
          name="image"
          id="image"
          accept="image/*"
          required
        />

        <button class="btn_modale">Modifier</button>
      </form>
    </div>
  </div>
  <!-- food table -->
  <section class="table">
    <div class="admin-table">
      <table>
        <tr>
          <th>Id</th>
          <th>Nom</th>
          <th>Type</th>
          <th>Description</th>
          <th>Price</th>
          <th>Image</th>

          <th>Actions</th>
        </tr>
        <tr v-for="food in allFood" :key="food.id">
          <td>{{ food.id }}</td>
          <td>{{ food.name }}</td>
          <td>{{ food.category.type }}</td>
          <td>{{ food.description }}</td>
          <td>{{ food.price }}</td>
          <td>
            <img
              :src="`http://127.0.0.1:8000${food.image}`"
              width="100"
              alt="plat"
            />
          </td>
          <td class="icons">
            <p
              class="edit"
              @click="
                toggleModale(
                  food.id,
                  food.name,
                  food.description,
                  food.price,
                  food.image
                )
              "
            >
              <i class="fas fa-edit"> Modifier</i>
            </p>

            <p class="supprimer" @click="Delete(food.id)">
              <i class="fas fa-trash"></i> supprimer
            </p>
          </td>
        </tr>
      </table>
    </div>
  </section>
  <!-- end food table -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    axios
      .get("food/all")
      .then((response) => {
        this.allFood = response.data.data;
      })
      .catch((error) => {
        console.log(error.message);
        // this.info = error.message;
      });
  },
  data() {
    return {
      allFood: [],

      revele: false,
      remember: false,
      update: {
        id: 0,
        name: "",
        description: "",
        price: 0,
        
      },
      image: null,
    };
  },
  methods: {
    getImage(event) {
      this.image = event.target.files[0];
    },
    // delete food
    Delete(id) {
      let confirm = window.confirm("Voulez-vous vraiment supprimé ce plat ?");

      if (confirm) {
        axios
          .delete(`food/delete/${id}`)
          .then((response) => {
            console.log(response.data);
            alert("ca a marche");
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    },

    // update food
    updateFood() {
      let id = this.update.id;

      let fd = new FormData();

      fd.append("image", this.image);
      axios
        .post("/uploads", fd)
        .then((response) => {
          axios
            .put(`food/update/${id}`, {
              ...this.update,
              image: response.data.url,
            })
            .then((response) => {
              const { data } = response;
              if (data.statut == "success") {
                alert(data.message);
              } else {
                alert(data.message);
              }
            })
            .catch((error) => {
              alert(error);
              console.log(error.message);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // modale
    toggleModale: function (id, name, description, price, image) {
      this.revele = !this.revele;

      this.update = {
        id: id,
        name: name,
        description: description,
        price: price,
        image: image,
      };
    },
  },
};
</script>

<style scoped>
section {
  padding: 20px 0px;
}

.table {
  position: relative;
  padding: 50px;
}

.admin-table {
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  border: 1px solid var(--border-color);
  width: 90%;
  margin: auto;
}

th,
td {
  border: 1px solid var(--border-color);
  padding: 15px;
  font-family: var(--second-police);
  font-size: 18px;
  text-align: center;
}

.icons {
  border-bottom: 1px solid var(--border-color);
  padding: 15px;
}

.icons p {
  cursor: pointer;
  padding-left: 15px;
  margin-top: 20px;
  text-align: center;
}

.icons p:hover {
  text-decoration: underline;
}

.container {
  position: relative;
  margin-top: 10px;
}

.edit {
  cursor: pointer;
}

.edit:hover i {
  text-decoration: underline;
}

/* style modale */
.modale h2 {
  text-align: center;
}

.bloc-modale {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
}

.couche {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modale {
  background: #f1f1f1;
  color: #333;
  padding: 50px;
  z-index: 99;
}

.btn-modale {
  position: absolute;
  top: 140px;
  right: 370px;
  width: 40px;
  text-align: center;
}

.modale form {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 600px;
}

.modale form input {
  background: #eee;
  padding: 16px;
  margin: 8px 0;
  width: 85%;
  border: 0;
  outline: none;
  border-radius: 5px;
  box-shadow: inset 7px 2px 10px #babebc, inset -5px -5px 12px #fff;
}

.btn_modale {
  border-radius: 20px;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: bold;
  padding: 15px 45px;
  margin: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 80ms ease-in;
}

.btn_modale {
  box-shadow: -5px -5px 10px #fff, 5px 5px 8px #babebc;
}

.btn_modale:active {
  box-shadow: inset 1px 1px 2px #babebc, inset -1px -1px 2px #fff;
}
/* end style modale */
</style>